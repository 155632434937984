<template>
  <div class="page">
    <div class="d-flex justify-content-center" style="height: 30%">
      <img src="../../assets/img/play-banner.png" alt="">
    </div>

    <div class="container d-flex justify-content-center" id="login-card">
      <PosLoginForm />
    </div>

    <p class="text-center mt-5">Powered By <a target="_blank" href="https://chrilantech.com">Lyt-Soft POS</a></p>
    <p class="text-center mt-5"><a target="_blank" href="https://wa.me/260958168829">Contact Support</a></p>
  </div>
</template>

<script>
import PosLoginForm from "@/components/point-of-sale/PosLoginForm";

export default {
  name: "PosLoginView",
  components: {PosLoginForm},
}
</script>

<style scoped>
#login-card {
  margin-top: -100px
}
</style>
