<template>
  <div class="d-flex flex-column justify-content-start pos-panel">
    <StockSearchBar />
    <div class="container-fluid">
      <div class="row content">
        <div class="pos-sales-table col-md-5 body">
          <CurrentSaleTable />
        </div>
        <div class="col-md-7 body d-flex flex-column">
          <CategoryBar class="category-bar" />
          <div class="stock-list">
            <StockItems />
          </div>
          <PaymentControls class="payment-controls" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StockSearchBar from "@/components/point-of-sale/stock-search/StockSearchBar.vue";
import CategoryBar from "@/components/point-of-sale/stock-search/CategoryBar.vue";
import CurrentSaleTable from "@/components/point-of-sale/sales/CurrentSaleTable.vue";
import StockItems from "@/components/point-of-sale/stock-search/StockItems.vue";
import PaymentControls from "@/components/point-of-sale/sales/PaymentControls.vue";

export default {
  name: "POSView",
  components: {
    PaymentControls,
    StockItems,
    CurrentSaleTable,
    CategoryBar,
    StockSearchBar,
  },
  data() {
    return {
      saleNumber: this.$route.query.saleNumber,
      dontReload: this.$route.query.dontReload,
    };
  },
};
</script>

<style lang="scss" scoped>
.pos-panel {
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
}

.content {
  height: 100%;
  padding: 0 10px 10px 10px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .pos-sales-table {
    display: none !important;
  }
}

@media (min-height: 769px) {
  .stock-list {
    height: 83vh !important;
  }
}

.body {
  background: white;
  padding: 0;
  overflow: hidden;
  position: relative;

  .category-bar {
    height: 53px;
    max-height: 53px;
    border-bottom: 1px solid #C9CACF;
  }

  .stock-list {
    height: 77vh;
    padding-bottom: 77px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .payment-controls {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    height: 75px;
  }
}

.body:nth-child(1) {
  border-radius: 0 0 0 20px;
}

.body:nth-child(2) {
  border-radius: 0 0 20px 0;
}
</style>
