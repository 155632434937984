import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'backOffice',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        openOnlineBackOffice: async (context, page = {name: `stock`}) => {
            let mobileUser = await Storage.getAdminUser();
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            user = user.Password;
            let device = await Device.getDeviceId()
            let obj = {mobileUser, store, user, page, device};
            obj = encryptObj(obj);
            return openUrl(backOfficePlatform + `?auth=` + obj);
        },
        searchExpenseAccounts: async (context, accountName) => {
            let store = await Storage.getDefaultStore();
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${store.StoreDB}/searchExpenseAccounts?accountName=` + accountName)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        capturePayout: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            payload.User = user.Username;
            return axios.post(backOfficeTomcatApi + `Payments/${store.StoreDB}/storePayout`, payload)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        captureCashDrop: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            let settings = await Storage.getDeviceSettings();
            payload.user = user.Username;
            payload.pc = settings.PC;
            return axios.post(backOfficeTomcatApi + `safeFunctions/${store.StoreDB}/cashierDepositToSafe`, payload)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        getCashSafes: async () => {
            let store = await Storage.getDefaultStore();
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${store.StoreDB}/getSafeByType?type=CASH`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        }
    }
}
