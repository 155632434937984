<template>
  <div class="payment-method-btn">
    <button class="lyt-soft-btn w-100" data-bs-toggle="modal" :data-bs-target="`#staticCurrencyBackdrop-${rowData.id}`">
      {{ rowData.name }} ({{ rowData.CurrencySymbol }})
    </button>

    <div class="modal fade" :id="`staticCurrencyBackdrop-${rowData.id}`" data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabindex="-1" :aria-labelledby="`staticCurrencyBackdrop-${rowData.id}Label`" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" :id="`staticCurrencyBackdrop-${rowData.id}Label`">Sale Details in
              {{ rowData.CurrencyName }} ({{ rowData.CurrencySymbol }})</h6>
            <a type="button" class="btn-close" data-bs-dismiss="modal" :id="`closeCurrency-${rowData.id}-Dialog`"
               aria-label="Close"></a>
          </div>
          <div class="modal-body">
            <div class="sale-lines-table">
              <ul class="sales-list">
                <li :key="index" v-for="(sale , index) in currentSaleLines">
                  <ForeignCurrencySaleLine :sale="sale" :symbol="rowData.CurrencySymbol" :rate="rowData.CurrencyRate"/>
                </li>
              </ul>
            </div>
          </div>

          <div class="modal-footer">
            <ForeignSaleDetails :rate="rowData.CurrencyRate" :symbol="rowData.CurrencySymbol" />
            <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</a>
            <a type="button" class="btn btn-primary" @click="$emit(`click`)">Process Transaction</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import ForeignCurrencySaleLine from "@/components/point-of-sale/sales/payment/ForeignCurrencySaleLine";
import ForeignSaleDetails from "@/components/point-of-sale/sales/ForeignSaleDetails";

export default {
  name: 'ForeignCurrency',
  components: {ForeignSaleDetails, ForeignCurrencySaleLine},
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },

  computed: {
    currentSale() {
      return this.$store.getters.getCurrentSale
    },
    currentSaleLines() {
      return this.$store.getters.getSaleLines
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.modal-backdrop {
  z-index: -1;
}

button {
  color: white;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  border-radius: 8px;
  height: 90%;
  width: 75%;
  max-width: 600px;
}

.payment-method-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
}

.sales-list {
  list-style: none;
  padding: 0 0 130px 0;

  li {
    border: 1px solid #E6E7EC;
  }
}
</style>