<template>
  <div class="page">
    <PaymentScreen/>
  </div>
</template>

<script>
import PaymentScreen from "@/components/point-of-sale/sales/payment/PaymenScreen";

export default {
  name: "PaymentView",
  beforeMount() {
    this.$store.commit(`generateSaleUUID`)
  },
  components: {PaymentScreen},
}
</script>

<style lang="scss" scoped>
.page {
  background: white;
  overflow-y: auto;
}
</style>
