<template>
  <div class="container-fluid navigation-bar p-1 px-2">

    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars"/>
    </button>

    <div class="navbar-logo"></div>
    <div class="navigation-box d-flex justify-content-center">
      <div class="d-flex flex-column" v-if="$store.getters.isTot">
        <img src="@/assets/img/caution.png" alt="Tot"
             style="background: black;border-radius: 10px;position: sticky;z-index: 100;margin-right: 50px"
             height="50px" width="50px">
      </div>


    </div>

    <div class="user-settings">
      <a class="burger float-start mx-3" v-if="showLoyalty" @click="checkLoyalty">
        <font-awesome-icon icon="heart-circle-check" v-if="!!$store.getters.getLoyaltyDetails.barcode"/>
        <font-awesome-icon icon="heart-circle-plus" v-else/>
      </a>
      <Notification/>

<!--      <button @click="syncData" class="burger">-->
<!--        <font-awesome-icon icon="arrows-rotate"/>-->
<!--      </button>-->
    </div>
    <button @click="syncData" class="burger">
      <font-awesome-icon icon="arrows-rotate"/>
    </button>

    <custom-modal title="Add Loyalty Customer To Sale" ref="scanLoyalty" @open="focusInput">
      <div v-if="!loyaltyDetails.barcode">
        <label for="loyalty-barcode">Scan Barcode</label>
        <input type="text" class="form-control" :disabled="$store.getters.getLoadingState" id="loyalty-barcode"
               v-model="loyaltyBarcode" ref="loyaltyCode"
               @keyup.enter="validateBarcode">
      </div>
      <div class="d-flex flex-column" v-else>
        <span class="text-sm text-secondary">Barcode : {{ loyaltyDetails.barcode }}</span>
        <div class="d-flex flex-row">
          Customer : {{ loyaltyDetails.name }} {{ loyaltyDetails.surname }}
          <a class="btn btn-sm btn-outline-primary mx-2" @click="$store.commit(`setLoyaltyDetails`,{})">
            <font-awesome-icon icon="close"/>
          </a>
        </div>
        <span>Contact : {{ loyaltyDetails.contact }}</span>
        Points Value : {{ loyaltyDetails.balance }} points - ({{ $utils.formatMoney(loyaltyDetails.pointsInCash) }})

      </div>
    </custom-modal>
  </div>
</template>

<script>


import Notification from "@/components/layout/navigation/Notification";
import CustomModal from "@/components/layout/utils/CustomModal";

export default {
  name: "NavigationBar",
  components: {CustomModal, Notification},
  computed: {
    salesScreens() {
      return [`pos`, `payment`, `split-payment`, `instruction`]
    },
    showLoyalty() {
      let isSalesScreen = this.salesScreens.includes(this.$route.name);
      return this.$store.getters.getHasLoyalty && isSalesScreen;
    },
    loyaltyDetails() {
      return this.$store.getters.getLoyaltyDetails;
    },
  },
  data() {
    return {
      loyaltyBarcode: null
    }
  },
  props: {
    toggle: {
      type: Function,
      require: true
    },
  },
  methods: {
    syncData() {
      this.$utils.showLoading();
      return window.location.reload(true);
    },
    tawkTo() {
      return window.open('https://tawk.to/LytSupport', '_self');
    },
    validateBarcode() {
      if (!isValidString(this.loyaltyBarcode)) return;
      this.$refs.scanLoyalty.closeModal();
      this.$utils.showLoading()
      return this.$store.dispatch(`checkLoyaltyBarcode`, this.loyaltyBarcode)
          .finally(() => {
            this.loyaltyBarcode = null;
            this.$refs.scanLoyalty.openModal();
          })
    },
    checkLoyalty() {
      return this.$refs.scanLoyalty.openModal();
    },
    async focusInput() {
      if (!!this.loyaltyDetails.barcode) return;
      await new Promise(resolve => setTimeout(resolve, 1000));
      return this.$refs.loyaltyCode.focus();
    },
  }
}
</script>

<style lang="scss" scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
  float: right;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.text-white {
  font-size: 30px;
}
.text-sm {
  font-size: 10px;
}
</style>
