import {authentication} from "@/services/auth";
import {Device} from "@/services/Device";
import {responses} from "@/utils/responses";
import Swal from "sweetalert2";
import Vue from 'vue'
import {utils} from "@/utils/utils";
import {errorHandler} from "@/services/errorHandler";
import queryStringify from "qs-stringify";
import store from "@/store";
import html2canvas from 'html2canvas';

window.serverApi = `https://lna.abc.co.zm/OnlineSalesServer/`
//  window.serverApi = `https://realtime.abc.co.zm:8443/TestSalesServer/`
// window.serverApi = `http://192.168.1.22:8998/OnlineSalesServer/`

window.ticketApi = `https://lna.abc.co.zm/TicketApp/`

window.kazangApi = `https://realtime.abc.co.zm:8443/kazangApi/api/`
 // window.kazangApi = `http://localhost:8080/kazangApi/api/`

window.gateWayApi = `https://realtime.abc.co.zm:8443/SandboxKazangPaymentGateway/api/GateWay/`
 // window.gateWayApi = `http://192.168.1.225:9333/KazangPaymentGateway/api/GateWay/`

window.franchiseTomcatApi = `https://tom9.abc.co.zm/Franchise_OnlineServer/`
// window.franchiseTomcatApi = `http://localhost:8080/Franchise_OnlineServer/`

window.loyaltyApi = `https://realtime.abc.co.zm:8443/loyalty_service/loyalty/`
// window.loyaltyApi = `http://localhost:8080/loyalty_service/loyalty/`

window.zedMobileApi = `https://lna.abc.co.zm/ZedMobileApi/`
// window.zedMobileApi = `http://localhost:8181/ZedMobileApi/`

window.backOfficeTomcatApi = `https://tom9.abc.co.zm/Lyt-SoftBackOfficeApi/`
// window.backOfficeTomcatApi = `http://localhost:8080/Lyt-SoftBackOfficeApi/`

window.subscriptionApi = `https://realtime.abc.co.zm:8443/subscription/api/`
// window.subscriptionApi = `http://localhost:8080/subscription/api/`

window.backOfficeApiPOST = franchiseTomcatApi+`proxyApi/lytSoftBackOffice/POST`;
window.backOfficeApiGET = franchiseTomcatApi+`proxyApi/lytSoftBackOffice/GET`;


// window.backOfficePlatform = `http://192.168.0.222:8082`;
window.backOfficePlatform = `https://admin.chrilantech.com`;

// window.hardwareApi = `http://192.168.1.140:9090/`;
window.hardwareApi = `http://localhost:9090/`;
window.smartinvoicesearch = `http://192.168.1.222:8080/Lyt-SoftBackOfficeApi/other/`;
window.gateWayApiPOST = franchiseTomcatApi+`proxyApi/gateWay/POST`;
window.gateWayApiGET = franchiseTomcatApi+`proxyApi/gateWay/GET`;

window.subscriptionApiPOST = franchiseTomcatApi+`proxyApi/subscriptionApi/POST`;
window.subscriptionApiGET = franchiseTomcatApi+`proxyApi/subscriptionApi/GET`;

window.franchiseApi = `https://chrilantech.com/LytSoftPosOnline/api/`
// window.franchiseApi = `http://127.0.0.1:8000/api/`

window.franchiseSpringApi = `https://lna.abc.co.zm/FranchiseSpringServer/`


window.auth = authentication;
window.Device = Device;
window.responses = responses;
window.errorHandler = errorHandler
window.routeExceptions = ['login','forgot-password','kazangOnline','test-success','test-failed','initKazangOnline',
    'signup','custom-install','custom-cart'];

const EventBus = new Vue()
window.EventBus = EventBus
window.log = (val) => console.log(val);
window.Swal = Swal;

window.generateUUID = () => {
    let uuid = '', i, random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        }
        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
}

window.$utils = utils;


window.screenShot = async () => {
    Swal.close();
    let sheet = store.getters.getActiveSheet;
    if (sheet) {
        try {
            await sheet.close();
            await new Promise(resolve => setTimeout(resolve, 300));
        } catch (r) {
        }
    }
    return html2canvas(document.body).then(canvas => {
        let data = canvas.toDataURL('image/png');
        return data.split(';base64,')[1]
    }).catch(err => {
        console.error(err);
    }).finally(async () => {
        if (sheet) {
            try {
                await sheet.open();
            } catch (w) {
            }
        }
    });
}

Vue.prototype.$utils = utils;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;
window.stringify = (object) => queryStringify(object);



window.pageOptions = {
    enabled: true,
    mode: 'pages',
    perPage: 10,
    position: 'bottom',
    perPageDropdown: [10, 100, 200],
    dropdownAllowAll: false,
    jumpFirstOrLast: true,
    pageLabel: 'page', // for 'pages' mode
}
window.openUrl = (url,target = '_blank') => window.open(url,target,"_blank","toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=yes, width=900, height=700");
window.encryptObj = (object) => window.btoa(JSON.stringify(object));
window.decryptObj = (string) => window.atob(JSON.parse(string));

window.isValidString = (string) => {
    if (!string) return false;
    string = string.toString().trim();
    return !(string === `null` || string === `undefined` || string === ``);

}