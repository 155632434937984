<template>
  <div class="product-card" @click="$emit('click')">
    <div class="stock-card_img mx-2 mt-2" v-if="itemImage">
      <img :src="itemImage" alt="Item Image">
    </div>
    <div class="card-body mx-3 p-1 d-flex justify-content-between flex-column">
      <span class="text-secondary text-sm">Stock Code : {{ stock.PLU }}</span>
      <h6 class="product-description">
        {{ description }} <br>
        <span v-if="canShowStock">
          <span v-if="stock.QTY > 0" class="badge bg-info">Qty in Stock : {{ $utils.formatQTY(stock.QTY) }}</span>
          <span v-else-if="stockSetting.NonStock " class="badge bg-info">Service</span>
          <span v-else class="badge bg-danger">Not in Stock</span>
        </span><br>
        <span class="product-description2">{{ description2 }}</span>
      </h6>
      <h5 class="product-price">{{ $utils.formatMoney(stock.SellingIncl) }}</h5>
    </div>
  </div>
</template>

<script>
import {utils as $utils} from "@/utils/utils";
import {Storage} from "@/services/Storage";

export default {
  name: "Product",
  props: {
    stock: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.showQty();
  },
  computed: {
    description() {
      return $utils.toPascalCase(this.stock.Description);
    },
    description2() {
      return $utils.toPascalCase(this.stock.Description2);
    },
    itemImage() {
      return this.stock.Image;
    },
    currentUser(){
      return this.$store.getters.getCurrentUser;
    },
  },
  data() {
    return {
      stockSetting: {
        NonStock: false
      },
      canShowStock : false,
      windowWidth: window.innerWidth,
      textLength: 50,
      image: require('/src/assets/img/no-image.png'),
    };
  },
  methods : {
    async showQty(){
      let hideQty = await this.$store.dispatch(`validateSecurityPermissionNoPrompt`,`hideQuantity`);
      return this.canShowStock = !hideQty;
    }
  },
  watch: {
    windowWidth(nh, oh) {
      if (nh < 400) {
        this.textLength = 25;
      }
    },
    'stock.PLU'(nv, ov) {
      return this.$store.dispatch('getStockSetting', nv).then(stockSettings => {
        if (stockSettings) {
          this.stockSetting = stockSettings;
        }
      })
    },
    'currentUser'(nv, ov) {
      if (nv){
        this.showQty();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h4, h5, h6 {
  margin: 0;
}

.example-card-img-responsive {
  width: 80px;
  height: 65px;
  margin: 10px;
  opacity: 0.8;
}

.product-card {
  height: 110px;
  transition: 200ms ease-in-out;
  overflow: hidden;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .product-description {
    font-weight: 600;
    color: #303030;
  }

  .product-description2 {
    font-weight: 400;
    color: #303030;
  }

  .product-price {
    font-weight: 700;
    color: #322f2f;
  }

  &:hover, &:focus {
    background: #0092f7;
    color: white;
    transform: scale(1.03);

    .product-description {
      color: white;
    }

    .product-price {
      color: white;
    }
  }
}

img {
  height: 94px;
  width: 94px;
  opacity: 0.7;
  border-radius: 10px;
}

.text-sm {
  font-size: 12px !important;
}
</style>
