import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const Device = {
  async requestPersistentStorage() {
    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then((persistent) => {
        if (persistent) {
          console.log(`Storage is ${persistent}`);
        } else {
          console.log(
            "Storage may be cleared by the UA under storage pressure."
          );
        }
      });
    }
  },

  async getDeviceId() {
    let id;
    try {
      await this.requestPersistentStorage();

      // Request access to the OPFS
      const fsHandle = await navigator.storage.getDirectory();
      console.log(fsHandle, "File system handle obtained");

      // Open the file named 'deviceID.txt'
      const fileHandle = await fsHandle.getFileHandle("deviceID.txt");

      // Get a file object from the file handle
      const file = await fileHandle.getFile();

      // Read the file content as text
      id = await file.text();

      console.log("Read Device ID from file:", id);
      return id;
    } catch (error) {
      console.log("Error reading device ID file:", error);
      id = localStorage.getItem("deviceRegistrationID");
    }

    if (id && id !== "undefined") {
      return id;
    }

    try {
      id = await this.getFingerPrintID();
    } catch (e) {
      // console.log("Error getting fingerprint ID:", e);
      id = generateUUID();
    }

    console.log(`Generated Device ID: ${id}`);

    try {
      const fsHandle = await navigator.storage.getDirectory();
      console.log(fsHandle, "Handle for writing device ID");

      const fileHandle = await fsHandle.getFileHandle("deviceID.txt", {
        create: true,
      });

      const writable = await fileHandle.createWritable();
      await writable.write(id);
      await writable.close();
    } catch (error) {
      console.log("Error saving device ID to file system:", error);
      console.log("Saved in local storage instead");
    }

    return id;
  },

  getFingerPrintID() {
    const fpPromise = FingerprintJS.load();
    return fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        console.log("Fingerprint ID obtained:", result.visitorId);
        return result.visitorId;
      });
  },

  getPCName(android) {
    if (android) return this.getDeviceId();
    return axios
      .get(hardwareApi + `utils/getPcName`)
      .then(({ data }) => data)
      .catch((err) =>
        errorHandler.tomcatError(err, false, "Hardware Service Error")
      );
  },
};
