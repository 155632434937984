import {DateTimeFormatter, LocalDate as LocalDate, LocalDateTime, Duration} from "@js-joda/core";
LocalDate.LocalDate;


let addIntForDate = (num) => num >= 10 ? num : '0' + num;

let tomorrow = LocalDate.now().plusDays(1);
tomorrow = tomorrow._year + '-' + addIntForDate(tomorrow._month) + '-' + addIntForDate(tomorrow._day);

let yesterday = LocalDate.now().minusDays(1);
yesterday = yesterday._year + '-' + addIntForDate(yesterday._month) + '-' + addIntForDate(yesterday._day);


function formatCustomDateTime(dateTime, pattern) {
    const day = addIntForDate(dateTime.dayOfMonth());
    const month = addIntForDate(dateTime.monthValue());
    const year = dateTime.year();
    const hour = addIntForDate(dateTime.hour());
    const minute = addIntForDate(dateTime.minute());
    const second = addIntForDate(dateTime.second());

    switch (pattern) {
        case "yyyy-MM-dd HH:mm:ss":
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        case "E dd MMM, HH:ss":
            return `${dateTime.dayOfWeek().name().substring(0, 3)} ${day} ${dateTime.month().name().substring(0, 3)}, ${hour}:${minute}`;
        case "dd/MM/yyyy, HH:ss":
            return `${day}/${month}/${year}, ${hour}:${minute}`;
        case "HH:mm":
            return `${hour}:${minute}`;
        default:
            throw new Error("Unsupported pattern");
    }
}

class TimeRangeManager {

    shortenDisplay(parsedDate) {
        if (!parsedDate) return `Invalid Date : ${parsedDate}`
        let formattedDate = LocalDateTime.parse(parsedDate, DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"));
        let now = LocalDateTime.now();
        if (formattedDate.toLocalDate().equals(now.toLocalDate())) {
            return `Today at ` + formatCustomDateTime(formattedDate, "HH:mm");
        } else if (formattedDate.toLocalDate().year() === now.toLocalDate().year()) {
            return formatCustomDateTime(formattedDate, "E dd MMM, HH:ss");
        } else {
            return formatCustomDateTime(formattedDate, "dd/MM/yyyy, HH:ss");
        }
    }

    shortJavaDateTimeObject(javaDateTimeObject) {
        let date = javaDateTimeObject.date;
        let time = javaDateTimeObject.time;
        let parsedDate = `${date.year}-${addIntForDate(date.month)}-${addIntForDate(date.day)} ${addIntForDate(time.hour)}:${addIntForDate(time.minute)}:${addIntForDate(time.minute)}`;
        return this.shortenDisplay(parsedDate);
    }

    getNow() {
        let today = LocalDate.now();
        today = today._year + '-' + addIntForDate(today._month) + '-' + addIntForDate(today._day);
        return today;
    }

    getThisMoment() {
        let date = LocalDateTime.now().format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));
        let time = LocalDateTime.now().format(DateTimeFormatter.ofPattern("hh:mm"));
        return date+`T`+time;
    }

    getNowMinusDays(days) {
        let today = LocalDate.now().minusDays(days);
        today = today._year + '-' + addIntForDate(today._month) + '-' + addIntForDate(today._day);
        return today;
    }

    getToday() {
        let today = LocalDate.now();
        today = today._year+'-'+addIntForDate(today._month)+'-'+addIntForDate(today._day);
        log(tomorrow)
        return { from : today,to : tomorrow}
    }
    getCustom(date , numberOfDays = 1){
        let fromDate = LocalDate.parse(date);
        fromDate = fromDate._year+'-'+addIntForDate(fromDate._month)+'-'+addIntForDate(fromDate._day);

        let toDate = LocalDate.parse(date).plusDays(numberOfDays);
        toDate = toDate._year+'-'+addIntForDate(toDate._month)+'-'+addIntForDate(toDate._day);
        return { from : fromDate,to : toDate}
    }

    getYesterday() {
        let today = LocalDate.now();
        today = today._year + '-' + addIntForDate(today._month) + '-' + addIntForDate(today._day);
        return {from: yesterday, to: today}
    }

    getLastWeek() {
        let startOfWeek = LocalDate.now().minusDays(7);
        startOfWeek = startOfWeek._year + '-' + addIntForDate(startOfWeek._month) + '-' + addIntForDate(startOfWeek._day)
        return {from: startOfWeek, to: tomorrow}
    }


    getThisMonth() {
        let startOfMonth = LocalDate.now().withDayOfMonth(1)
            .format(DateTimeFormatter.ofPattern("yyyy-MM-dd"));
        return {from: startOfMonth, to: tomorrow}
    }

    getPreviousMonth() {
        let firstDayOfLastMonth = LocalDate.now().minusMonths(1);
        firstDayOfLastMonth = firstDayOfLastMonth._year+'-'+addIntForDate(firstDayOfLastMonth._month)+'-01'

        let now = LocalDate.now();
        let firstDayOfThisMonth = now._year + '-' + addIntForDate(now._month) + '-01';

        return { from : firstDayOfLastMonth,to : firstDayOfThisMonth}
    }

    getThisYear() {
        let startOfYear = LocalDate.now()._year + '-01-01'
        return {from: startOfYear, to: tomorrow}
    }

    getForNoOfDays(noOfDays) {
        let startOfWeek = LocalDate.now().minusDays(noOfDays);
        startOfWeek = startOfWeek._year + '-' + addIntForDate(startOfWeek._month) + '-' + addIntForDate(startOfWeek._day)
        return {from: startOfWeek, to: tomorrow}
    }

    getDateDiff(date1, date2) {
        date1 = LocalDateTime.parse(date1 + ' 00:00:00', DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        date2 = LocalDateTime.parse(date2 + ' 00:00:00', DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss"))
        let duration = Duration.between(date1, date2);
        return duration.toDays();
    }

    isGreaterThanTwoMonths(range) {
        return this.getDateDiff(range.from, range.to) >= 60;
    }



}

export default TimeRangeManager = new TimeRangeManager();
