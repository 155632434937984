<template>
  <div class="modal fade" id="userSecurityModal"
       data-bs-backdrop="static"
       data-bs-keyboard="false"
       tabindex="-1"
       aria-labelledby="securityModalLabel"
       aria-hidden="true">
    <div class="modal-dialog" v-if="security">
      <div class="modal-content">
        <form @submit.prevent="checkSecurity">
          <div class="modal-header">
            <h5 class="modal-title" id="securityModalLabel">User not allowed to {{ security.Type }}</h5>
            <button type="button" id="securityModalDismiss" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close" @click="dismissSecurity"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p>Input supervisor password To Allow {{ security.requestedUser }} to {{ security.Type }}</p>
                <span class="text-sm text-warning">Or wait for supervisor to approve from supervisor app</span>
              </div>
              <div class="col-12">
                <input type="password" class="form-control" v-model="supervisorPassword"
                       placeholder="Supervisor password" aria-label="Supervisor password"
                       aria-describedby="Supervisor password">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary" >Confirm</button>
            <a type="button" id="securityModalDismissBtn" class="btn btn-secondary" :disabled="cancelling"
               @click="dismissSecurity" data-bs-dismiss="modal">Cancel</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
  name: "UserSecurityChecker",
  computed: {
    isOpen() {
      return this.$store.getters.isUserSecurityOpen
    },
    security() {
      return this.$store.getters.getUserSecurity
    }
  },
  data() {
    return {
      supervisorPassword: '',
      intervalV2 : null,
      response: undefined,
      storeDB: undefined,
      checkingAuth: false,
      cancelling: false,
    }
  },
  methods: {
    async requestAuthorization() {
      this.$store.commit(`setUserSecurityOpen`,false)
      let payload = Object.assign({}, this.security)
      payload.Type = payload.Type + ` : ` + this.$store.getters.getOverrideDetails;
      payload.base64image = await screenShot()
      delete payload.promise

      return this.$store.dispatch('requestSecurityOverride', payload)
          .then((data) => {
            this.response = data
            // setTimeout(() => this.checkIfAuthorized(), 2 * 1000);
            this.intervalV2 = setInterval(() => {
              this.checkIfAuthorized();
            }, 10 * 1000);
          })
    },
    checkIfAuthorized() {
      this.checkingAuth = true
      if (this.response.status === `Approved`) {
        return this.returnResponse({
          allowed: true,
          supervisor: this.response.ApprovedUser,
          cashier: this.response.requestedUser,
        })
      } else if (this.response.CancedRequest) {
        return this.returnResponse({allowed: false})
      }
      return this.$store.dispatch('checkSecurityOverrideRequest', this.response.id)
          .then((data) => {
            if (data.status !== 'PENDING') {
              if (data.status === 'Approved') {
                return this.returnResponse({
                  allowed: true,
                  supervisor: data.ApprovedUser,
                  cashier: data.requestedUser,
                })
              } else if (data.CancedRequest) {
                return this.returnResponse({allowed: false})
              } else {
                return this.returnResponse({allowed: false})
              }
            }
          }).finally(() => {
            this.checkingAuth = false;
          })
    },
    dismissSecurity() {
      this.cancelling = true;
      let btn = document.getElementById('securityModalDismiss')
      btn.click()
      let request = this.security
      this.response = undefined
      this.supervisorPassword = ''
      this.$store.dispatch('cancelSecurityOverrideRequest', request.id)
      this.returnResponse({allowed: false})
      this.$store.commit('setUserSecurityOpen', false)
      this.$store.commit(`setOverrideDetails`, ``);
    },
    async checkSecurity() {
      let password = this.supervisorPassword
      if (password) {
        let security = this.security.Type
        let securities = await Storage.getAllSecurities();
        let user = await Storage.getCurrentUser();
        let users = await Storage.getAllUsers();
        let supervisor = users.find(user => user.Password.toLowerCase() === password.toLowerCase());
        if (!supervisor) {
          responses.showWarning('Incorrect password')
          this.returnResponse({allowed: false})

        } else {
          let supervisorType = supervisor.Type;
          let supervisorGroup = securities.find(sec => sec.GroupName.toLowerCase() === supervisorType.toLowerCase());
          if (!supervisorGroup[security]) {
            responses.showWarning(`User ${supervisor.Username} not allowed to authorize ${security}`)
            this.returnResponse({allowed: false})
          } else {
            let response = {
              allowed: true,
              supervisor: supervisor.Username,
              cashier: user.Username,
            };

            this.returnResponse(response)
          }

        }
      } else {
        await this.checkIfAuthorized()
      }
    },
    returnResponse(response) {
      if (!this.security)return ;
      this.security.promise.resolve(response)
      //call this after resolving the promise otherwise the override will never be allowed
      document.getElementById('securityModalDismiss').click()
      this.supervisorPassword = '';
      this.$store.commit(`setOverrideDetails`, ``);
      this.$store.commit('setUserSecurityOpen', false)
      clearInterval(this.intervalV2);
    },
    checkRequest() {
      if (this.isOpen) {
        let store = this.$store.getters.getDefaultStore;
        this.storeDB = store.StoreDB;
        this.requestAuthorization();
      } else {

      }
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.checkRequest();
    }, 200);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.intervalV2);
  },
}
</script>


<style scoped lang="scss">
.text-sm {
  font-size: 12px;
}
</style>