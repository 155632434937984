import store from '../store/index'
function formatNumber(decimalCount, amount, symbol, thousands = ",", decimal = ".") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        // Convert the amount to a fixed-point notation
        amount = Number(amount).toFixed(decimalCount);

        // Use regex to remove unnecessary trailing zeros and the decimal point if it is an integer
        let [integerPart, fractionalPart] = amount.split('.');

        // Trimming the fractional part of trailing zeros if necessary
        if (fractionalPart) {
            fractionalPart = fractionalPart.replace(/0+$/, '');
        }

        let i = Math.abs(parseInt(integerPart)).toString(); // Ensure the integer part is positive for the split
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            symbol +
            ' ' +
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (fractionalPart ? decimal + fractionalPart : '')
        );
    } catch (e) {
        console.error(e);
    }
}


export const utils = {
    toPascalCase(string) {
        return string
            .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
            .replace(/^\w/, s => s.toUpperCase());
    },
    cutLength(string, limit = 45) {
        if (string.length < limit) {
            return string
        } else {
            return string.toString().substring(0, limit).concat('...')
        }
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",",isCurrency = true,currency = store.getters.getBaseCurrency.Symbol) {
        if (!isCurrency || !currency){
            return formatNumber(decimalCount, amount, '', thousands, decimal);
        }
        return formatNumber(decimalCount, amount, currency, thousands, decimal);
    },
    formatQTY(amount) {
        return this.formatMoney(amount, 3, '.', ',',false);
    },
    removeIf(arr, predicate) {
        return arr.filter(predicate);
    },
    remove(arr, obj) {
        return arr.filter(v => v !== obj)
    },
    roundAmount(amount , decimalPlaces = 2){
        if (typeof amount === 'string'){
            amount = amount.replace(`,`,``);
            amount = Number(amount);
        }
        amount = amount.toFixed(decimalPlaces);
        return Number(amount);
    },
    showLoading : ()=>store.commit(`setLoadingState`,true),
    hideLoading : ()=>store.commit(`setLoadingState`,false),
}
