<template>
  <div class="page">
    <div class="container">
      <div class="row py-3 d-flex justify-content-center align-items-center ">
        <div class="amount-box col-2">
          <h1 class="amount-paid change-amount">{{ $utils.formatMoney(receipt.paidAmount) }}</h1>
          <h4 class="amount-label">Total Paid</h4>
        </div>
        <div class="amount-box col-2 amount">
          <h1 class="amount-paid change-amount">{{ $utils.formatMoney(receipt.change - receipt.tip) }}</h1>
          <h4 class="amount-label">Cash Change</h4>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center fixed-bottom mb-2">
          <button @click="reprintLastInvoice" class="btn btn-primary mx-3 reprint-btn">Reprint</button>
          <button @click="nextSale" class="next-button cent">Next Sale</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewInvoicesView",
  data() {
    return {
      email: ''
    }
  }, computed: {
    receipt() {
      return this.$store.getters.getLatestInvoice
    }
  },
  methods: {
    nextSale() {
      return Promise.all([
        this.$store.dispatch(`getAllStock`),
        this.$store.dispatch(`getAllActiveSpecials`),
        this.proceed(),
      ]);
    },
    proceed(){
      let settings  = this.$store.getters.getDeviceSettings;
      let autoLogout = settings.AutoLog === 1 || settings.PosType === `WaiterStation`;
      if (autoLogout) return this.$router.push({ name : `poslogin` });
      return this.$router.push({ name : `pos` })
    },
    reprintLastInvoice() {
      return this.$store.dispatch(`reprintLastInvoice`);
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  background: white;
  height: 100%;
}

.amount-box {
  height: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;

  .amount-label {
    color: #40434A;
  }

  .amount-paid {
    font-size: 2rem;
    padding: 0;
    margin: 0;
    outline: transparent;
    border: none;
    height: 44px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
}

.amount-box:first-child {
  align-items: end;

  .amount-paid {
    padding-bottom: 9px;
    text-align: right;
    max-width: 250px;
    width: 100%;

    &:focus, &:active {
      border-bottom: 3px solid #9ccc65 !important;
    }
  }
}

.amount-box:nth-child(2) {
  align-items: start;
  border-left: 1px solid #d2d2d2;
}

button {
  width: 100%;
  font-weight: 700;
  height: 60px;
  border-radius: 10px;
  max-width: 500px;
}

.next-button {
  background: #9ccc65;
  color: white;

}
</style>
