<template>
  <div id="app">
    <button type="button" class="btn btn-primary visually-hidden" data-bs-toggle="modal"
            data-bs-target="#userSecurityModal" id="checkSecurityBtn">
      Open
    </button>
    <UserSecurityChecker />
    <Loader />
    <router-view/>
  </div>
</template>
<script>
import Loader from "@/components/layout/Loader";
import UserSecurityChecker from "@/components/UserSecurityChecker.vue";
export default {
  components: {UserSecurityChecker,Loader},
  beforeMount() {
    if (!routeExceptions.includes(this.name)) {
      this.$store.dispatch('syncData');
    }
  },
  computed : {
    loading(){
      return this.$store.getters.getLoadingState;
    },
    isOpen() {
      return this.$store.getters.isUserSecurityOpen
    }
  },
  data() {
    return {
      name: this.$route.name,
      resizeListener: (e) => this.$store.commit('setWindowWidth'),
    }
  },
  created() {
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  watch: {
    isOpen: function (isOpen, wasOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          document.getElementById('checkSecurityBtn').click()
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "assets/css/styles";
</style>
