import axios from "axios"
import {Storage} from "@/services/Storage";
import {responses} from "@/utils/responses";

export default {
    name: 'stockTake',
    state: {
        stockTakeList: [],
        stockTakeItems: [],
        stockTakeRoute: '/stock-take',
    },
    getters: {
        getStockTakeList: (state) => state.stockTakeList,
        getStockTakeRoute: (state) => state.stockTakeRoute,
        getStockTakeItems: (state) => state.stockTakeItems,
    },

    mutations: {
        setStockTakeList: (state, data) => state.stockTakeList = data,
        setStockTakeRoute: (state, data) => state.stockTakeRoute = data,
        setStockTakeItems: async (state, data) => {
            state.stockTakeItems = data.map(item=>{
                item.countedQty = '';
                return item;
            })
        },
    },
    actions: {
        async getStockTakeList(context) {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `savedstocktakes/${store.StoreDB}/getAll`)
                .then(({data}) => context.commit('setStockTakeList', data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        async getStockTakeItemsList(context, id) {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `stocktakesavedlist/${store.StoreDB}/getPluByID/${id}`)
                .then(({data}) => context.commit('setStockTakeItems', data))
                .catch((err) => errorHandler.tomcatError(err))

        },
        getstock: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            const globalDateRange = context.getters.getGlobalDateRange;

            // Construct query parameters from globalDateRange
            const queryParams = new URLSearchParams({
                user: 'All',
                limit: 5000000,
                ...globalDateRange
            }).toString();

            return axios.get(`${franchiseApi}StockTake/getAll/${store.StoreDB}?${queryParams}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err));
        },

        getStockByDepartment: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `stock/${store.StoreDB}/getByDepartment`, {params: payload})
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },

        async finalizeStockTake(context) {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            let settings = await Storage.getDeviceSettings();
            let pc = settings.PC;
            let items = context.getters.getStockTakeItems
                .filter(item=> item.countedQty !== '')
                .map(item => {
                    return {
                        'plu': item.PLU,
                        'counted': parseFloat(item.countedQty),
                    }
                });
            let data = {
                user: user.Username,
                PC : pc,
                items: items
            };
            return axios.post(serverApi + `stocktake/${store.StoreDB}`,data)
                .then(({data}) => {
                    axios.post(franchiseTomcatApi+`emailrecord/${store.StoreDB}/emailStockTake`,{stockTakeID : data.id});
                    context.commit('setStockTakeItems', []);
                    context.commit('setStockTakeRoute', '/stock-take');
                    context.dispatch('getAllStock')
                }).catch((err) => errorHandler.tomcatError(err))
        },
    }
}
