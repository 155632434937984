<template>
  <page-with-back-btn title="Payment Type : Split">
    <SplitPaymentScreen />
  </page-with-back-btn>
</template>

<script>
import SplitPaymentScreen from "@/components/point-of-sale/sales/payment/SplitPaymentScreen";
import BackNavigationBar from "@/components/layout/navigation/BackNavigationBar";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn";
export default {
  name: "SplitPaymentView",
  components: {PageWithBackBtn, BackNavigationBar, SplitPaymentScreen}
}
</script>