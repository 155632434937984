<template>
  <div class="page">
    <div class="card shadow-lg rounded-3 py-3" style="border:none" v-if="currentUser">
      <div class="card-body p-1">
        <h1 class="card-title mb-3  text-uppercase" style="font-weight:700; color: #666666"><span
            style="color: #0092f7">Cash</span> Up</h1>
        <form @submit.prevent="confirmationText">
          <div class="row">
            <div class="col">
              <div class="form-floating mb-3" v-if="currentUser.Type.toLowerCase() !== 'admin'">
                <input type="text" class="form-control"
                       v-model="cashup_user" id="floatingTextInput1"
                       placeholder="Cashier" required readonly>
                <label for="floatingTextInput1">Cashier</label>
              </div>

              <div v-else class="form-floating mb-3">
                <select v-model="cashup_user" class="form-select" id="current_cashup_user">
                  <option v-for="(user,n) in users" :key="n+1" :value="user.Username">
                    {{ user.Username }}
                  </option>
                </select>
                <label for="current_cashup_user">Cashier</label>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.hundreds" id="hundreds" placeholder="K100 Notes"
                       required>
                <label for="hundreds"> <b>{{ $utils.formatMoney(100) }} Notes</b> : {{ $utils.formatMoney(hundredsVal) }} </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.fifties" id="fifties" placeholder="K50 Notes"
                       required>
                <label for="fifties"> <b>{{ $utils.formatMoney(50) }} Notes</b> : {{ $utils.formatMoney(fiftiesVal) }} </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.twenties" id="twenties" placeholder="K20 Notes"
                       required>
                <label for="twenties"> <b>{{ $utils.formatMoney(20) }} Notes</b> : {{ $utils.formatMoney(twentiesVal) }} </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.tens" id="tens" placeholder="K10 Notes"
                       required>
                <label for="tens"> <b>{{ $utils.formatMoney(10) }} Notes</b> : {{ $utils.formatMoney(tensVal) }} </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.fives" id="fives" placeholder="K5 Notes"
                       required>
                <label for="fives"> <b>{{ $utils.formatMoney(5) }} Notes</b> : {{ $utils.formatMoney(fivesVal) }} </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.twos" id="twos" placeholder="K2 Notes"
                       required>
                <label for="twos"> <b>{{ $utils.formatMoney(2) }} Notes</b> : {{ $utils.formatMoney(twosVal) }} </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.ones" id="ones" placeholder="K1 Coins"
                       required>
                <label for="ones"> <b>{{ $utils.formatMoney(1) }} Coins</b> : {{ $utils.formatMoney(onesVal) }} </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.fifty_ngwees" id="fifty_ngwees" placeholder="50 Ngwee Coins"
                       required>
                <label for="fifty_ngwees"> <b>50 Ngwee coins</b> : {{ $utils.formatMoney(fifty_ngweesVal) }} </label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-floating mb-3">
                <input type="number" min="0" class="form-control"
                       v-model="cash.ten_ngwees" id="ten_ngwees" placeholder="10 Ngwee Coins"
                       required>
                <label for="ten_ngwees"> <b>10 Ngwee Coins</b> : {{ $utils.formatMoney(ten_ngweesVal) }} </label>
              </div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control"
                   id="cash-total" placeholder="Cash Total"
                   :value="$utils.formatMoney(totalCashVal)" readonly >
            <label for="cash-total"> <b>Cash Total</b> </label>
          </div>

          <div class="form-floating mb-3">
            <input type="number" min="0" step="0.01" class="form-control"
                   v-model="card" id="card-total"
                   placeholder="Card Total"
                   required>
            <label for="card-total"> <b>Card Total</b> </label>
          </div>

          <div class="form-floating mb-3" v-for="method in otherMethods">
            <input type="number" min="0" step="0.01" class="form-control"
                   v-model="method.amount" :id="method.id+`-total`"
                   :placeholder="`${method.name} Total`"
                   required>
            <label :for="method.id+`-total`"> <b>{{ method.name }} Total</b> </label>
          </div>

          <div class="text-center">
            <button :disabled="loading" type="submit" class="btn-cashup">
              <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    <FinalizeCashupSheet :cashup="cashup" ref="detailsheet"/>
  </div>
</template>

<script>
import FinalizeCashupSheet from "@/components/point-of-sale/cashup/FinalizeCashupSheet";
import {Storage} from "@/services/Storage";

export default {
  name: "BasicCashupForm",
  components: {FinalizeCashupSheet},
  async beforeMount() {
    let user = await Storage.getCurrentUser();
    this.currentUser = user;

    this.users = await Storage.getAllUsers();
    this.cashup_user = user.Username
  },
  computed: {
    set() {
      return this.$store.getters.getDeviceSettings
    },
    hundredsVal() {
      return Number(Number(this.cash.hundreds) * 100)
    },
    fiftiesVal() {
      return Number(Number(this.cash.fifties) * 50)
    },
    twentiesVal() {
      return Number(Number(this.cash.twenties) * 20)
    },
    tensVal() {
      return Number(Number(this.cash.tens) * 10)
    },
    fivesVal() {
      return Number(Number(this.cash.fives) * 5)
    },
    twosVal() {
      return Number(Number(this.cash.twos) * 2)
    },
    onesVal() {
      return Number(this.cash.ones)
    },
    fifty_ngweesVal() {
      return Number(Number(this.cash.fifty_ngwees) * 0.5)
    },

    ten_ngweesVal() {
      return Number(Number(this.cash.ten_ngwees) * 0.1)
    },
    otherMethods() {
      return this.$store.getters.getPaymentMethods
          .filter(method => method.active)
          .map(method => {
            method.amount = 0;
            return method;
          });
    },
    totalCashVal() {
      return this.hundredsVal
          + this.fiftiesVal
          + this.twentiesVal
          + this.tensVal
          + this.fivesVal
          + this.twosVal
          + this.onesVal
          + this.fifty_ngweesVal
          + this.ten_ngweesVal
    },
    loading(){
      return this.$store.getters.getLoadingState;
    },
  },
  data() {
    return {
      cashup: {},
      pc_cashup: false,
      cashup_user: '',
      currentUser: null,
      users : [],
      cash: {
        hundreds: 0,
        fifties: 0,
        twenties: 0,
        tens: 0,
        fives: 0,
        twos: 0,
        ones: 0,
        fifty_ngwees: 0,
        ten_ngwees: 0,
      },
      card: 0,
    }
  },

  methods: {
    async confirmationText() {
      return Swal.fire({
        title: 'Are you sure?',
        text: "Please make sure your figures are correct!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, I am sure !'
      }).then((result) => {
        if (result.isConfirmed) {
          return this.submitCashUp();
        }
      })
    },
    async submitCashUp() {
      let other = this.otherMethods.map(method=>{
        return {
          id : method.id,
          amount : Number(method.amount)
        }
      });
      let settings = await Storage.getDeviceSettings();
      let data = {
        cash: parseFloat(this.totalCashVal),
        card: parseFloat(this.card),
        pc: settings.PC,
        user: this.cashup_user,
        other : other,
      }
      let isAllowed  = await this.$store.dispatch(`validateSecurityPermissionNoPrompt`,`viewCashupDetails`);
      if (!isAllowed){
        return this.$store.dispatch('cashUpUser',data)
            .then(() => this.$router.push({name: 'float'}))
            .finally(()=>this.$utils.hideLoading())
      }
      this.$utils.showLoading()
      return this.$store.dispatch('getcashUpDiff', data)
          .then((res) => {
            this.cashup = res.response;
            this.openSheet();
          }).finally(() => this.$utils.hideLoading());
    },
    openSheet() {
      this.$refs.detailsheet.open();
    },
  }

}
</script>

<style lang="scss" scoped>
.btn-cashup {
  background: #1e88e5;
  color: white;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
}

.page {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
