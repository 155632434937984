<template>
  <div class="container">

<!--    modals start-->
    <div class="modal fade" id="accPaymentsModalVisible" tabindex="-1" aria-labelledby="payoutsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="payoutsModalLabel">Sales</h5>


            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

          </div>
          <div class="modal-body">
            <span v-if="fsales.length > 0">{{ fsales[0].date }}</span>
            <vue-good-table :search-options="{ enabled: true }" :columns="modalViewAccountspayments" :rows="fsales"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">
                <!-- Customize the content of each row if needed -->
                <!-- ... (unchanged code) ... -->
              </template>
            </vue-good-table>                  </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="cashToSafeModalVisible" tabindex="-1" aria-labelledby="accPaymentsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="payoutsModalLabel">Sold</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <vue-good-table :search-options="{ enabled: true }" :columns="modalViewCashToSale" :rows="fsold"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">
                <!-- Customize the content of each row if needed -->
                <!-- ... (unchanged code) ... -->
              </template>
            </vue-good-table>                  </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="voidsModalVisible" tabindex="-1" aria-labelledby="cashToSafeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="payoutsModalLabel">Voids</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <vue-good-table :search-options="{ enabled: true }" :columns="modalViewVoids" :rows="fvoids"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">

              </template>
            </vue-good-table>                  </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="AccountspayVisible" tabindex="-1" aria-labelledby="cashToSafeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="payoutsModalLabel">Account pay</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <vue-good-table :search-options="{ enabled: true }" :columns="modalAccountspayVisible" :rows="fAccountsPay"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">

              </template>
            </vue-good-table>                  </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="refunModalVisible" tabindex="-1" aria-labelledby="creditsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="payoutsModalLabel">Refunds</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <vue-good-table :search-options="{ enabled: true }" :columns="modalViewRefunds" :rows="frefunds"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">

              </template>
            </vue-good-table>                  </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
<!--    modals end-->

    <div class="card">
      <div class="row">
        <h5 class="title">Cash</h5>
        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.CashFloat)" id="float"
                   placeholder="Cash Float" required readonly>
            <label for="float">Float</label>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Cashrequired)" id="cash-required"
                   placeholder="Cash Required" required readonly>
            <label for="cash-required">Required</label>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Cashdeclared)" id="cash-declared"
                   placeholder="Cash Declared" required readonly>
            <label for="cash-declared">Declared</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Cashdifference)" id="cash-difference"
                   placeholder="Cash Declared" :style="colorCash" readonly>
            <label for="cash-difference">Difference</label>
          </div>
        </div>
      </div>
      <div class="row">
        <h5 class="title">Card</h5>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Cardrequired)" id="card-required"
                   placeholder="Card Required" required readonly>
            <label for="card-required">Required</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Carddeclared)" id="card-declared"
                   placeholder="Card Declared" required readonly>
            <label for="card-declared">Declared</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.CardDifference)" id="card-difference"
                   placeholder="Card Difference" :style="colorCard" readonly>
            <label for="card-difference">Difference</label>
          </div>
        </div>
      </div>

      <div class="row" v-for="method in cashup.other">
        <h5 class="title">{{ method.name }}</h5>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(method.required,2,'.',`,`,true,method.currencySymbol)" :id="`${method.id}-required`"
                   :placeholder="`${method.name} Required`" required readonly>
            <label :for="`${method.id}-required`">Required</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(method.declared,2,'.',`,`,true,method.currencySymbol)" :id="`${method.id}-declared`"
                   :placeholder="`${method.name} Declared`" required readonly>
            <label :for="`${method.id}-declared`">Declared</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(method.difference,2,'.',`,`,true,method.currencySymbol)" :id="`${method.id}-difference`"
                   :placeholder="`${method.name} Difference`" :style="getMethodColor(method)" readonly>
            <label :for="`${method.id}-difference`">Difference</label>
          </div>
        </div>
      </div>
      <div class="row">
        <h5 class="title">Combined (Cash + Card)</h5>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Combinedrequired)" id="combined-required"
                   placeholder="Combined Required" required readonly>
            <label for="combined-required">Required</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Combineddeclared)" id="combined-declared"
                   placeholder="Combined Declared" required readonly>
            <label for="combined-declared">Declared</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.Combineddifference)" id="combined-difference"
                   placeholder="Card Difference" :style="colorCombined" readonly>
            <label for="combined-difference">Difference</label>
          </div>
        </div>
      </div>

      <div class="row">
        <h5 class="title">Audits</h5>
        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="`${cashup.voids} voids`" id="void-count"
                   placeholder="Voids Count" readonly>
            <label for="void-count">Voids Count</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.voidsValue)" id="voids-value"
                   placeholder="Voids Value" readonly>
            <label for="voids-value">Voids Value</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="`${cashup.refunds} refunds`" id="ref-count"
                   placeholder="Refunds Count" readonly>
            <label for="ref-count">Refunds</label>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-floating">
            <input type="text" class="form-control"
                   :value="$utils.formatMoney(cashup.refundsVal)" id="ref-value"
                   placeholder="Refunds Value" readonly>
            <label for="ref-value">Refunds Value</label>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button :disabled="loading" class="btn-cashup" @click="confirmationText">
        <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Finalize
      </button>
      <button class="btn btn-primary" style="margin-right: 10px;" data-bs-toggle="modal" data-bs-target="#accPaymentsModalVisible" data-bs-whatever="@mdo" @click="openSalesModal">Sales</button>
      <button class="btn btn-primary" style="margin-right: 10px;" data-bs-toggle="modal" data-bs-target="#cashToSafeModalVisible" data-bs-whatever="@mdo" @click="openSoldModal">Sold</button>
      <button class="btn btn-primary" style="margin-right: 10px;" data-bs-toggle="modal" data-bs-target="#voidsModalVisible" data-bs-whatever="@mdo" @click="openVoidsModal">Voids</button>
      <button class="btn btn-primary" style="margin-right: 10px;" data-bs-toggle="modal" data-bs-target="#refunModalVisible" data-bs-whatever="@mdo" @click="openRefundsModal">Refunds</button>
      <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#AccountspayVisible" data-bs-whatever="@mdo" @click="openAccountspay">AccountPayments</button>

    </div>
  </div>
</template>

<script>
import {Storage} from "@/services/Storage";
import axios from "axios";
export default {
  name: "CashupSummary",
  props: {cashup: {type: Object, required: true}},
  data() {
    return {
      pageOptions: {
        enabled: true, // Enable pagination
        perPage: 10, // Number of items per page
        mode: 'records' // Pagination mode: 'records' or 'pages'
        // You can add more pagination options as needed
      },
      accPaymentsModalVisible: false,
      cashToSafeModalVisible: false,
      voidsModalVisible: false,
      refunModalVisible: false,
      AccountspayVisible: false,
      cashups: [],
      fsales: [],
      fsold: [],
      fvoids: [],
      fAccountsPay: [],
      frefunds: [],
      loading: false,
      form: {
        user : null,
        date : null
      },
      modalViewRefunds: [
        {
          label: `Date`,
          field: `date`,
        },
        {
          label: `Invoice number`,
          field: `invnum`,
        },
        {
          label: `Payment Total`,
          field: `paymenttotal`,
        },
        {
          label: `Payment type`,
          field: `paymenttype`,
        },
        {
          label: `PC`,
          field: `pc`,
        },
        {
          label: `Sale Total`,
          field: `saletotal`,
        },
        {
          label: `User`,
          field: `user`,
          sortable: false,
        },
      ],
      modalColumns: [
        {
          label: 'Invoice#',
          field: 'name',
        },
        {
          label: 'User',
          field: 'age',
        },
        {
          label: 'Date',
          field: 'size',
        },
        {
          label: 'Payment Type',
          field: 'month',
        },
        {
          label: 'Total',
          field: 'day',
        },
        {
          label: 'PC',
          field: 'day',
        },
        {
          label: 'Action',
          field: 'day',
        },
      ],
      modalAccountspayVisible: [
        {
          label: 'User',
          field: 'user',
        },
        {
          label: 'payment-Type',
          field: 'paymentType',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'DebtorName',
          field: 'debtorName',
        },
        {
          label: 'DebtorID',
          field: 'debtorID',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'cashed up',
          field: 'cashedup',
        },
        {
          label: 'Amount',
          field: 'amount',
        },   ],
      modalColumnsviewPayouts: [
        {
          label: 'Reason',
          field: 'name',
        },
        {
          label: 'Date',
          field: 'age',
        },
        {
          label: 'Amount',
          field: 'size',
        },
        {
          label: 'Vat',
          field: 'month',
        },
        {
          label: 'User',
          field: 'day',
        },
        {
          label: 'PC',
          field: 'day',
        },
        {
          label: 'Supplier',
          field: 'day',
        },
        {
          label: 'Action',
          field: 'day',
        },      ],
      modalViewAccountspayments: [
        {
          label: 'Invoice Number',
          field: 'invnum',
        },
        {
          label: 'Payment Type',
          field: 'paymenttype',
        },
        {
          label: 'Pc',
          field: 'pc',
        },
        {
          label: 'Sale Total',
          field: 'saletotal',
        },
        {
          label: 'User',
          field: 'user',
        },
      ],
      modalViewCashToSale: [
        {
          label: 'PLU',
          field: 'PLU',
        },
        {
          label: 'Description',
          field: 'Description',
        },
        {
          label: 'Cost',
          field: 'cost',
        },

        {
          label: 'Profit',
          field: 'profit',
        },
        {
          label: 'Quantity',
          field: 'qty',
        },
        {
          label: 'Total',
          field: 'total',
        },      ],
      modalViewVoids: [
        {
          label: 'ID',
          field: 'Id',
        },
        {
          label: 'CashUp',
          field: 'CashUp',
        },
        {
          label: 'Date Time',
          field: 'DateTime',
        },
        {
          label: 'Pc',
          field: 'PC',
        },
        {
          label: 'Reason',
          field: 'Reason',
        },
        {
          label: 'Super Visor',
          field: 'SuperVisor',
        },
        {
          label: 'User',
          field: 'User',
        },
        {
          label: 'Description',
          field: 'VoidDescriptiom',
        },
        {
          label: 'number',
          field: 'VoidNum',
        },
        {
          label: 'PLU',
          field: 'VoidPlu',
        },
        {
          label: 'Quantity',
          field: 'VoidQty',
        },
        {
          label: 'Value',
          field: 'VoidValue',
        },
        {
          label: 'Department',
          field: 'department',
        },

      ],
      modalViewCredits: [
        {
          label: 'Debtor',
          field: 'name',
        },
        {
          label: 'Old Invoice',
          field: 'age',
        },
        {
          label: 'New Invoice',
          field: 'size',
        },
        {
          label: 'Date',
          field: 'month',
        },
        {
          label: 'User',
          field: 'day',
        },
        {
          label: 'PC',
          field: 'day',
        },
        {
          label: 'Amount',
          field: 'day',
        },
        {
          label: 'Action',
          field: 'day',
        },      ],
    }
  },
  computed: {
    colorCash() {
      let val = this.cashup.Cashdifference;
      let color = 'red'
      if (val === 0) {
        color = 'green'
      } else if (val > 0) {
        color = 'orange'
      }
      return {color: color};
    },

    colorCard() {
      let val = this.cashup.CardDifference;
      let color = 'red'
      if (val === 0) {
        color = 'green'
      } else if (val > 0) {
        color = 'orange'
      }
      return {color: color};
    },

    colorCombined() {
      let val = this.cashup.Combineddifference;
      let color = 'red'
      if (val === 0) {
        color = 'green'
      } else if (val > 0) {
        color = 'orange'
      }
      return {color: color};
    },
  },
  methods: {
    confirmationText(){
      return Swal.fire({
        title: 'Are you sure?',
        text: "Please make sure your figures are correct!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Yes, I am sure !`
      }).then((result) => {
        if (result.isConfirmed) {
          return this.finalize();
        }
      })
    },
    finalize() {
      let other = (this.cashup.other || []).map(method => ({
        id: method.id,
        amount: Number(method.declared)
      }));

      let data = {
        cash: this.cashup.Cashdeclared,
        card: this.cashup.Carddeclared,
        user: this.cashup.User,
        PC: this.cashup.PC,
        other: other
      };
      return this.$store.dispatch('cashUpUser', data)
          .then(() => this.$router.push({name: 'float'}))
          .finally(() => this.$utils.hideLoading())
    },



    getMethodColor(paymentMethod){
      const diff = paymentMethod.difference;
      let color = 'green';
      if (diff > 0){
        color = 'orange';
      }

      if (diff < 0){
        color = 'red';
      }
      return { color : color }
    },
    openSalesModal() {
      this.fetchsales(); // Fetch sales data
      this.accPaymentsModalVisible = true; // Show sales modal
    },
    openSoldModal() {
      this.fetchSold(); // Fetch sold data
      this.cashToSafeModalVisible = true; // Show sold modal
    },
    openVoidsModal() {
      this.fetchVoids(); // Fetch voids data
      this.voidsModalVisible = true; // Show voids modal
    },
    openRefundsModal() {
      this.fetchRefunds(); // Fetch refunds data
      this.refunModalVisible = true; // Show refunds modal
    },

    openAccountspay() {
      this.fetchAccountsPay(); // Fetch refunds data
      this.AccountspayVisible = true; // Show refunds modal
    },
    async fetchsales() {
      let store = await Storage.getDefaultStore();
      let settings   = await Storage.getDeviceSettings();
      this.device = settings.PC;
      let user  = await Storage.getCurrentUser();
      this.userName = user.Username;
      const apiUrl = `https://realtime.abc.co.zm:8443/ReportsApi/cashup/${store.StoreDB}/getPreCashupSales?user=${this.userName}&pc=${this.device}`;
      axios.get(apiUrl)
          .then(({data}) => {
            this.fsales = data;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    },

    async fetchSold() {
      let store = await Storage.getDefaultStore();
      let settings   = await Storage.getDeviceSettings();
      this.device = settings.PC;
      let user  = await Storage.getCurrentUser();
      this.userName = user.Username;
      const apiUrl = `https://realtime.abc.co.zm:8443/ReportsApi/cashup/${store.StoreDB}/getProductsSoldPreCashup?user=${this.userName}&pc=${this.device}`;
      axios.get(apiUrl)
          .then(({data}) => {
            this.fsold = data;
          })
          .catch ((error) => {
            console.error("Error fetching data from API:", error);
          });
    },

    async fetchVoids() {
      let store = await Storage.getDefaultStore();
      let settings   = await Storage.getDeviceSettings();
      this.device = settings.PC;
      let user  = await Storage.getCurrentUser();
      this.userName = user.Username;
      const apiUrl = `https://realtime.abc.co.zm:8443/ReportsApi/cashup/${store.StoreDB}/getPreCashupVoids?user=${this.userName}&pc=${this.device}`;
      axios.get(apiUrl)
          .then(({data}) => {
            this.fvoids = data;
          })
          .catch ((error) => {
            console.error("Error fetching data from API:", error);
          });
    },

    async fetchAccountsPay() {
      let store = await Storage.getDefaultStore();
      let settings   = await Storage.getDeviceSettings();
      this.device = settings.PC;
      let user  = await Storage.getCurrentUser();
      this.userName = user.Username;
      const apiUrl = `https://realtime.abc.co.zm:8443/ReportsApi/cashup/${store.StoreDB}/getPreCashupAccountPayments?pc=${this.device}&user=${this.userName}`;
      axios.get(apiUrl)
          .then(({data}) => {
            this.fAccountsPay = data;
          })
          .catch ((error) => {
            console.error("Error fetching data from API:", error);
          });
    },

    async fetchRefunds() {
      let store = await Storage.getDefaultStore();
      let settings   = await Storage.getDeviceSettings();
      this.device = settings.PC;
      let user  = await Storage.getCurrentUser();
      this.userName = user.Username;
      const apiUrl = `https://realtime.abc.co.zm:8443/ReportsApi/cashup/${store.StoreDB}/getPreCashupRefunds?user=${this.userName}&pc=${this.device}`;
      axios.get(apiUrl)
          .then(({data}) => {
            this.frefunds = data;
          }).catch ((error) =>{
        console.error("Error fetching data from API:", error);
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  overflow-y: scroll;
}
.card {
  padding: 0.5em;
}
.col-md-4 {
  margin-bottom: 10px;
}

.col-md-3 {
  margin-bottom: 10px;
}

.btn-cashup {
  background: #1e88e5;
  color: white;
  font-weight: 600;
  display: block;
  position: center;
  width: 100%;
  height: 45px;
}

button {
  margin-bottom: 10px;
}
</style>
