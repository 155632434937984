<template>
  <div class="sheet">
    <div>
      <h2 class="disc-header">Discount</h2><br>
      <h4 class="disc-header">Sale Total : {{ $utils.formatMoney(saleTotal) }}</h4>
    </div>

    <div class="container">
      <div class="wrapper row d-flex justify-content-center">
        <input id="option-3" name="select" type="radio" v-model="type" value="Value">
        <input id="option-4" name="select" type="radio" v-model="type" value="Percentage">
        <label class="option option-1" for="option-3">
          <div class="dot"></div>
          <span>Value (#)</span>
        </label>
        <label class="option option-2" for="option-4">
          <div class="dot"></div>
          <span>Percentage (%)</span>
        </label>
      </div>
    </div>
    <br><br>
    <div class="container mb-2">
      <div class="row d-flex justify-content-center">
        <label>
          <input class="input-amount" readonly
                 oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '0');"
                 pattern="[+-]?([0-9]*[.])?[0-9]+" type="text" v-model="amount">
        </label>
        <br><br>
        <div class="container button-pane">
          <div class="row text-center d-flex flex-column align-items-center">
            <div class="col-12 d-inline-flex justify-content-center">
              <button @click="inputNumber($event)" class="btn" type="button">1</button>
              <button @click="inputNumber($event)" class="btn" type="button">2</button>
              <button @click="inputNumber($event)" class="btn" type="button">3</button>
            </div>
            <div class="col-12 d-inline-flex justify-content-center">
              <button @click="inputNumber($event)" class="btn" type="button">4</button>
              <button @click="inputNumber($event)" class="btn" type="button">5</button>
              <button @click="inputNumber($event)" class="btn" type="button">6</button>
            </div>
            <div class="col-12 d-inline-flex justify-content-center">
              <button @click="inputNumber($event)" class="btn" type="button">7</button>
              <button @click="inputNumber($event)" class="btn" type="button">8</button>
              <button @click="inputNumber($event)" class="btn" type="button">9</button>
            </div>
            <div class="col-12 d-inline-flex justify-content-center">
              <button @click="addPoint" class="btn" type="button">
                .
              </button>
              <button @click="inputNumber($event)" class="btn" type="button">0</button>
              <button @click="backspace" class="btn" type="button">
                <font-awesome-icon icon="fa-solid fa-delete-left"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <h4 class="disc-header">New Sale Total : {{ $utils.formatMoney(newSaleTotal) }}</h4>
    <h5 class="disc-header">Discount Total : {{ $utils.formatMoney(discountAmount) }}</h5>
    <div class="d-flex justify-content-center fixed-bottom p-3">
      <button @click="confirmDiscount" class="next-button cent">Confirm</button>
    </div>
  </div>
</template>

<script>
import {responses} from "@/utils/responses";

export default {
  name: "DiscountSetting",
  data() {
    return {
      type: "Percentage",
      amount: '0'
    }
  },
  computed: {
    saleTotal() {
      return this.$store.getters.getSaleTotal + this.$store.getters.getTipAmount;
    },
    discountAmount() {
      let amount;
      if (this.type === 'Percentage') {
        amount = (this.amount / 100) * this.saleTotal
      } else {
        amount = this.amount;
      }
      return amount;
    },
    newSaleTotal() {
      return this.saleTotal - this.discountAmount;
    }
  },
  methods: {
    confirmDiscount() {
      if (this.type === 'Percentage' && this.amount > 100){
        return responses.showWarning(`Cannot Discount more than 100%`)
      }else if (this.type === 'Value' && this.amount > this.saleTotal){
        return responses.showWarning(`Cannot Discount more than Sale Total`)
      }
      this.$store.commit('applyDiscount', {
        type: this.type,
        value: this.amount,
        total: this.discountAmount,
      });
      responses.showSuccess('Discount Applied!')
      this.$emit('click');
    },
    addPoint() {
      if (!this.amount.includes('.') && this.amount !== '0') {
        this.amount += '.'
      }
    },
    inputNumber(event) {
      let elem = event.target
      if (elem) {
        let txt = elem.textContent || elem.innerText;
        if (txt) {
          if (this.amount === '0') {
            this.amount = ''
          }
          this.amount += txt
        }
      }
    },
    backspace() {
      let amount = this.amount
      let l = amount.length
      this.amount = amount.substring(0, l - 1);
      l = this.amount.length
      if (l === 0) {
        this.amount = '0'
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.disc-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  height: 50px;
  margin: 0 10px;
  display: flex !important;
  flex-direction: row;
  gap: 10px;
  //box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
}

.wrapper .option {
  background: #fff;
  height: 100%;
  max-width: 200px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}

.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#option-3:checked:checked ~ .option-1,
#option-4:checked:checked ~ .option-2 {
  border-color: #0069d9;
  background: #0069d9;
}

#option-3:checked:checked ~ .option-1 .dot,
#option-4:checked:checked ~ .option-2 .dot {
  background: #fff;
}

#option-3:checked:checked ~ .option-1 .dot::before,
#option-4:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.wrapper .option span {
  font-size: 15px;
  color: #808080;
}

#option-3:checked:checked ~ .option-1 span,
#option-4:checked:checked ~ .option-2 span {
  color: #fff;
}

.next-button {
  width: 100%;
  max-width: 500px;
  color: white;
  font-weight: 700;
  height: 60px;
  border-radius: 10px;
}

button {
  background: #0092f7;
  border-radius: 10px !important;
  border: 1px solid #9e9e9e !important;
  height: 60px;
  width: 100px;
  outline: none;
  margin: 1px;
  box-shadow: -2px -2px 5px white, 3px 3px 5px rgb(0 0 0 / 10%);
}

.amount-box {
  height: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;

  .amount-label {
    color: #40434A;
  }

  .amount-paid {
    font-size: 2rem;
    padding: 0;
    margin: 0;
    outline: transparent;
    border: none;
    height: 44px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
}

.amount-box:first-child {
  align-items: end;

  .amount-paid {
    padding-bottom: 9px;
    text-align: right;
    max-width: 250px;
    width: 100%;

    &:focus, &:active {
      border-bottom: 3px solid #0092f7 !important;
    }
  }
}

.amount-box:nth-child(2) {
  align-items: start;
  border-left: 1px solid #d2d2d2;
}

.btn {
  display: inline-block;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-amount {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 350px;
  height: 35px;
  border-radius: 10px;
  border: none;
  outline: transparent;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #363636;
}

</style>
