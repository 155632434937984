import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/layout/Layout";
import PosLoginView from "@/views/point-of-sale/PosLoginView";
import POSView from "@/views/point-of-sale/POSView";
import FloatView from "@/views/point-of-sale/floatCashup/FloatView";
import ViewInvoicesView from "@/views/point-of-sale/payment/ViewInvoicesView";
import SplitPaymentView from "@/views/point-of-sale/payment/SplitPaymentView";
import PaymentView from "@/views/point-of-sale/payment/PaymentView";
import CashupView from "@/views/point-of-sale/floatCashup/CashupView";
import LayByPayment from "@/views/point-of-sale/sale/LayByPayment";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: "poslogin",
        component: PosLoginView,
    },
    {
        path: '/select-subscription-type',
        name: "select-subscription-type",
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "select-subscription-type" */ '../views/setup/SelectSubscriptionType')
    },
    {

        path: '/',
        component: Layout,
        children: [
            {
                path: '/pos',
                name: 'pos',
                component: POSView,
            },
            {
                path: '/view-sale',
                name: "viewSale",
                component: () => import(/* webpackChunkName: "ViewSale" */'../views/point-of-sale/sale/ViewSaleView'),
            },
            {
                path: '/stock-take',
                name: "stock-take",
                component: () => import(/* webpackChunkName: "stock-take" */ '../views/boffice/StockTake/StockTakeListView'),
            },
            {
                path: '/run-stock-take',
                name: "run-stock-take",
                component: () => import(/* webpackChunkName: "run-stock-take" */ '../views/boffice/StockTake/StockTakeItemsView'),
            },
            {
                path: '/StockRecords',
                name: "StockRecords",
                component: () => import(/* webpackChunkName: "stock-take" */ '../views/boffice/StockTake/StockRecords'),
            },

            {
                path: '/payment',
                name: "payment",
                component: PaymentView
            },
            {
                path: '/lay-by-payment',
                name: "lay-by-payment",
                component: LayByPayment
            },

            {
                path: '/split-payment',
                name: "split-payment",
                component: SplitPaymentView
            },
            {
                path: '/view-payment',
                name: "viewPayment",
                component: ViewInvoicesView
            },
            {
                path: '/float',
                name: "float",
                component: FloatView,
            },
            {
                path: '/instruction',
                props: true,
                name: "instruction",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "instruction" */ '../views/point-of-sale/cookingInstruction/CookingInstruction'),
            },
            {
                path: '/cashup',
                name: "cashup",
                component: CashupView,
            },

            {
                path: '/cashup-records',
                name: "cashup-records",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "cashup-records" */ '../views/point-of-sale/floatCashup/CashupRecordsView.vue'),
            },

            {
                path: '/sales-records',
                name: "salesRecords",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "salesRecords" */ '../views/point-of-sale/SalesRecordsView'),
            },
            {
                path: '/lay-by-sales',
                name: "lay-by-sales",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "lay-by-sales" */ '../views/point-of-sale/LayBy/LayBySales'),
            },
            {
                path: '/TicketRequest',
                name: "TicketRequest",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "salesRecords" */ '../views/point-of-sale/TicketRequest.vue'),
            },
            {
                path: '/ViewOpenTicket',
                name: 'ViewOpenTicket',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/point-of-sale/ViewOpenTicket.vue'),
            },
            {
                path: '/ViewClosedTicket',
                name: 'ViewClosedTicket',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "ViewClosedTicket" */ '../views/point-of-sale/ViewClosedTicket.vue'),
            },
            {
                path: '/ticket/:id',
                name: 'TicketDetail',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "TicketDetail" */ '../views/point-of-sale/TicketDetails.vue'),
                // component: TicketDetail,
            },
            {
                path: '/settings',
                name: "settings",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "settings" */ '../views/boffice/settings/SettingsView'),
            },

            {
                path: '/view-invoice/:invoice',
                name: "viewInvoice",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "viewInvoice" */ '../views/point-of-sale/InvoiceDetailsView'),
            },
            {
                path: '/loyalty-customers',
                name: "loyalty-customers",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "loyalty-customers" */ '../views/point-of-sale/Loyalty/LoyaltyCustomers'),
            },

            {
                path: '/refund-invoice/:invoice',
                name: "refundInvoice",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "refundInvoice" */ '../views/point-of-sale/RefundInvoiceView'),
            },

            {
                path: '/tables',
                name: "tables",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "tables" */ '../views/point-of-sale/tables/AllTablesView'),
            },

            {
                path: '/CreateVoucher',
                name: "CreateVoucher",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "create-table" */ '../views/point-of-sale/Voucher/CreateVoucher'),
            },
            {
                path: '/create-table',
                name: "create-table",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "create-table" */ '../views/point-of-sale/tables/CreateTableView'),
            },

            {
                path: '/select-customer',
                name: "select-customer",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "select-customer" */ '../views/point-of-sale/Accounts/SelectCustomerView'),
            },

            {
                path: '/create-debtor',
                name: "create-debtor",
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "create-debtor" */ '../views/point-of-sale/Accounts/CreateDebtorView'),
            },

            {
                path: '/subscription-accounts',
                name: 'subscription-accounts',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "subscription-accounts" */ '../views/setup/Expired/SubscriptionsAndAccountsView'),
            },
            {
                path: '/merge-tables',
                name: 'merge-tables',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "merge-tables" */ '../views/point-of-sale/tables/MergeTables'),
            },
            {
                path: '/split-table',
                name: 'split-table',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "split-table" */ '../views/point-of-sale/tables/SplitTableView'),
            },

        ]
    },

    {
        path: '/email-settings',
        name: "email-settings",
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "email-settings" */ '../views/boffice/settings/EmailSetupView'),
    },

    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "login"*/ '../views/LoginView.vue'),
    },
    {
        path: '/setup',
        name: 'setup',
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "setup"*/ '../views/setup/AppSetupLogin.vue')
    },

    {
        path: '/system-expired',
        name: 'system-expired',
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "system-expired"*/ '../views/setup/Expired/SystemExpired')
    },

    {
        path: '/make-subscription-payment',
        name: 'make-subscription-payment',
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "make-subscription-payment"*/ '../views/setup/Expired/MonthlyPaymentView')
    },

    {
        path: '/expire-extension',
        name: 'expire-extension',
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "expire-extension"*/ '../views/setup/Expired/ExtensionSuccess')
    },
    {
        path: '/ticket',
        name: 'ticket',
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "expire-extension"*/ '../views/boffice/StockTake/Ticket.vue')
    },

    {
        path: '*',
        name: "notfound",
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "notfound" */ '../views/point-of-sale/PosLoginView.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

router.beforeEach(async (to, from, next) => {
    let exceptions = routeExceptions;
    if (!exceptions.includes(to.name)) {
        let res = await auth.isAdminLoggedIn()
        if (!res) {
            next({name: 'login'})
        } else {
            const IsItABackButton = window.popStateDetected
            window.popStateDetected = false
            if (IsItABackButton && from.name === `system-expired`) {
                next(false)
                return responses.showWarning(`System Expired`)
            }
            next()
        }
    } else {
        next()
    }
})


export default router

