<template>
  <div class="drawer" v-if="curUser">
    <div class="user-avatar">
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.Username }}</h5>
        <p>{{ curUser.Type }}</p>
        <p>{{ defaultStore }}</p>
      </div>
    </div>
    <div class="routes">
      <ul>
        <li v-if="isUpdateAvailable">
          <a @click="updateApp">
            <div class="icon-box">
              <font-awesome-icon class="icon text-info" icon="fa-solid fa-exclamation-circle"/>
            </div>
            <div class="d-flex flex-column text-center">
              Update Is Available
              <span class="text-sm" @click="updateApp">Click to Update</span>
            </div>
          </a>
        </li>
        <div class="box-container mt-2"  v-if="isSmartInvoiceLive && shouldSmartInvoiceSignUp" @click="loginToOnlineBackOfficeSmartInvoice">
          <div class="box red-background-blink">
            <h6 class="route-btn w-100 bg-transparent text-white"> Smart Invoicing Sign up </h6>
          </div>
        </div>


        <li :class="hasNoFloatAssigned">
          <router-link @click.native="back" :to="{ name : 'pos' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="basket-shopping"/>
            </div>
            Sales
          </router-link>
        </li>

        <li>
          <router-link @click.native="back" :to="{ name : 'salesRecords' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-receipt"/>
            </div>
            Invoices
          </router-link>
        </li>

        <li v-if="deviceSettings && deviceSettings.layby">
          <router-link @click.native="back" :to="{ name : 'lay-by-sales' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="circle-dollar-to-slot"/>
            </div>
            Sales On LayBy
          </router-link>
        </li>
        <li v-if="isLoyaltyActivated">
          <router-link @click.native="back" :to="{ name : 'loyalty-customers' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="heart-circle-check"/>
            </div>
            Loyalty Customers
          </router-link>
        </li>

        <li>
          <a @click="loginToOnlineBackOffice">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="business-time"/>
            </div>
            Back Office (Admin)
          </a>
        </li>

        <li>
          <a @click="stockTake" >
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="boxes"/>
            </div>
            Stock Take
          </a>
        </li>

        <li>
          <a @click="cashup">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-coins"/>
            </div>
            End User Shift
          </a>
        </li>

        <li>
          <a @click="cashupRecords">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="magnifying-glass-chart"/>
            </div>
            Cashup Records
          </a>
        </li>

        <li>
          <a @click="ViewOpenTicket">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-receipt" />
            </div>
            View Tickets
          </a>
        </li>
        <li>
          <a @click="TicketRoute">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-gear" />
            </div>
            Report an Issue
          </a>
        </li>
        <li>
          <a @click="settings">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-gear"/>
            </div>
            Device Settings
          </a>
        </li>
        <li>
          <router-link @click.native="back" :to="{ name : 'poslogin' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-arrow-right-from-bracket"/>
            </div>
            Log out
          </router-link>
        </li>
      </ul>
      <br>
      <div class="last-upd">
        Last Updated : {{ lastUpdated }}
      </div>
    </div>
  </div>

</template>

<script>
import Swal from "sweetalert2";
import {mapActions} from "vuex";

export default {
  name: "NavigationDrawer",
  props: {
    close: {
      type: Function,
      required: true,
    }
  },
  beforeMount() {
    this.checkSmartInvoicing();
    this.validateLogin();
    auth.isStoreSelected();

  },
  computed: {
    defaultStore() {
      return this.$store.getters.getDefaultStore.StoreName
    },
    lastUpdated() {
      return this.$store.getters.getLasUpdated
    },
    curUser() {
      return this.$store.getters.getCurrentUser
    },
    deviceSettings() {
      return this.$store.getters.getDeviceSettings;
    },
    isLoyaltyActivated() {
      return this.$store.getters.getHasLoyalty;
    },
    isUpdateAvailable() {
      return this.$store.getters.getUpdateAvailability;
    },
    hasNoFloatAssigned() {
      return this.$route.name === "float" ? 'disabled' : '';
    },
    setMsg() {
      return this.curUser.Type === 'admin' ? '' : 'blocked'
    },
    shouldSmartInvoiceSignUp() {
      return !this.$store.getters.getSmartInvoicingStatus && !this.$store.getters.getDocSubmissionStatus;
    },
    isSmartInvoiceLive() {
      return this.$store.getters.getSmartInvoicingLive;
    },
  },
  methods: {
    ...mapActions(['checkSmartInvoicing']),
    async validateLogin() {
      let isLoggedIn = await auth.isCashierLoggedIn();
      if (!isLoggedIn) {
        return this.$router.push({name: 'poslogin'})
      }
    },
    async loginToOnlineBackOffice() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, `Boffice`);
      if (isAllowed) await this.$store.dispatch(`openOnlineBackOffice`)
      return this.back();
    },
    async loginToOnlineBackOfficeSmartInvoice() {

      this.$store.dispatch(`openOnlineBackOffice`,{
        name : `smart-invoicing-setup`
      })
      return this.back();
    },
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    },
    async settings() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, `Settings`);
      if (isAllowed) await this.$router.push({name: `settings`})
      return this.back();
    },
    SystemSupport(){
       this.$router.push({ name : `ticket` })
     },
    updateApp() {
      this.$store.commit(`setUpdateAvailability`, false);
      return window.location.reload(true);
    },
    async ViewOpenTicket() {
      this.$router.push({name: 'ViewOpenTicket'})
      this.back()
    },
    async TicketRoute() {
      this.$router.push({name: 'TicketRequest'})
      this.back()
    },
    async cashupRecords(){
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`,`cashUpReports`);
      if (isAllowed) await this.$router.push({ name : `cashup-records` })
      return this.back();
    },
    async stockTake(){
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`,`StockTake`);
      if (!isAllowed) return this.back();
      const route = this.$store.getters.getStockTakeRoute;
      await this.$router.push(route);
      return this.back();
    },
    async cashup() {
      return this.$router.push({name: `cashup`})
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .routes {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;

        a {
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          padding-left: 10px;
          text-decoration: none;
          color: #1c1c1c;

          .icon-box {
            width: 35px;
            margin-right: 10px;
            display: flex;
            justify-content: center;

            .icon {
              font-size: 25px;
              color: #34373C;
            }
          }

          &:hover, &.router-link-exact-active {
            background: #f1f1f1;
            color: #3a71e8;
            //218efd 3a71e8
            font-weight: 700;

            .icon-box {
              .icon {
                color: #3a71e8;
              }
            }
          }
        }
      }
    }
  }

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: #1f2937;
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #ffffff;
          font-weight: 600;
        }
      }

    }
  }
}

ul {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}

.box-container {
  display: flex;
  justify-content: center; /* Center contents horizontally */
  margin-bottom: 10px; /* Adjust as needed */
}

.red-background-blink {
  background-color: red;
  animation: blink 1s infinite;
  padding: 10px 20px; /* Adjust height and width */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.box {
  display: inline-block;
}

.text-white {
  color: white;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

</style>
