import {Storage} from "@/services/Storage";
import axios from "axios";
import queryStringify from "qs-stringify";

export default {
    name: 'lay-by',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        searchLayByCustomers: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.get(serverApi + `layby/${store.StoreDB}/customers`, {
                data: payload
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        getLayBuyByID: async (context, id) => {
            let store = await Storage.getDefaultStore();
            return axios.get(serverApi + `layby/${store.StoreDB}/${id}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        searchLayBySales: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            payload = {
                ...context.getters.getGlobalDateRange,
                ...payload
            }
            payload = `?` + queryStringify(payload);
            return axios.get(serverApi + `layby/${store.StoreDB}` + payload)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        createLayByCustomer: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            let user = await Storage.getCurrentUser();
            payload.pc = settings.PC;
            payload.createdby = user.Username;
            return axios.post(serverApi + `layby/${store.StoreDB}/customer`, payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        // Initial step for handling Kazang payments
        firstKazangPaymentStepLayBy: async ({dispatch, getters}, payload) => {
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            let saleDetails = getters.getSaleDetails;
            let amount = Number(saleDetails.total) - Number(getters.getSaleDiscount.total);
            if (typeof payload === `object` && payload.onlyPayment) {
                amount = payload.amount;
                payload = {customerContact: payload.customerContact, onlyPayment: true, layByID: payload.id}; // Adjust payload for simple payment scenario
                return dispatch('sendSimplePaymentLayBy', {payload, store, amount});
            }
            let valid = {
                PC: settings.PC,
                items: getters.getSaleLines
            };
            return axios.post(serverApi + `invoice/${store.StoreDB}/checkStockAvailability`, valid)
                .then(({data}) => {
                    if (data.valid) {
                        payload = {customerContact: payload}; // Adjust payload for simple payment scenario
                        return dispatch('sendSimplePaymentLayBy', {payload, store, amount});
                    }
                    throw new Error(data.errorMessage)
                }).catch(err => errorHandler.tomcatError(err));
        },

        // Sends a simpler payment request when payload is not an object
        sendSimplePaymentLayBy: ({dispatch, getters}, {payload, store, amount}) => {
            return axios.post(`${kazangApi}pay/${store.StoreDB}`, {
                session: getters.getKazangSessionID,
                amount: Number(amount.toFixed(2)),
                cell: payload.customerContact
            }).then(({data}) => {
                data.customerContact = payload.customerContact;
                data.onlyPayment = payload.onlyPayment;
                data.layByID = payload.layByID;
                return dispatch('handlePaymentResponseLayBy', {data, amount});
            }).catch(err => dispatch('handlePaymentErrorLayBy', err));
        },

        // Handles the response from payment API calls
        handlePaymentResponseLayBy: ({dispatch}, {data, amount, payload = null}) => {
            if (!data.secondStep && data.Response === '0') {
                if (data.onlyPayment) {
                    let obj = {
                        id: data.layByID,
                        paymentMethod: `Kazang`,
                        amount
                    }
                    return dispatch(`acceptLayByPayment`, obj)
                }
                return dispatch('processKazangLayByPayment', {
                    amount,
                    SecondRefrence: data.SecondRefrence
                });
            } else if (data.Response === '3' || data.Response === '41') {
                return dispatch('resetKazangUser', data.Response);
            } else if (data.Response !== '0') {
                throw new Error(data.Print);
            }
            console.log(`Attempting Step 2`);
            return dispatch('retryKazangPaymentStep', {data, payload});
        },

        // Manages retry mechanism for payment steps
        retryKazangPaymentStepLayBy: ({dispatch}, {data, payload}) => {
            let attemptCount = 0;
            const maxAttempts = 35;
            let lastError = null;

            return new Promise((resolve, reject) => {
                const attemptKazangStep = () => {
                    attemptCount++;
                    console.log(`Attempt ${attemptCount} of ${maxAttempts}`);

                    if (attemptCount > maxAttempts) {
                        console.error('Max attempts reached. Stopping retries.');
                        reject(lastError || 'Max attempts reached without specific error message.');
                        return;
                    }

                    return dispatch('secondKazangStep', {data, payload})
                        .then(response => {
                            if (response.success) {
                                resolve(response);  // Resolve on successful response
                                return;
                            } else {
                                // Log and prepare for another retry
                                lastError = response.message || 'Non-successful response.';
                                console.log(`Retry due to error: ${lastError}`);
                                setTimeout(attemptKazangStep, 3000);  // Retry after a delay
                            }
                        })
                        .catch(error => {
                            // Even on errors, prepare for another retry unless max attempts are reached
                            lastError = error.toString();
                            console.error(`Dispatch error on attempt ${attemptCount}: ${lastError}`);
                            setTimeout(attemptKazangStep, 3000);
                        });
                };

                attemptKazangStep();  // Initiate the first attempt
            });
        },

        // Handles any payment errors
        handlePaymentError: ({commit}, err) => {
            commit('clearKazangSession');
            errorHandler.tomcatError(err, true);
            return Promise.reject(err); // Ensuring errors are propagated back if necessary
        },

        processLayByPayment: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(serverApi + `layby/${store.StoreDB}/create`, payload)
                .then(async ({data}) => {
                    context.dispatch(`printLayBySale`, data.id)
                    return Promise.all([
                        context.commit('setCurrentSale', 0),
                        context.commit('setTipAmount', 0),
                        context.commit('clearDiscount'),
                        context.commit('setSaleDetails', {}),
                        context.commit('setCurrentSale', 0),
                        context.commit('setCustomer', ""),
                        context.commit('setLPO', {
                            debtorID: ``,
                            lpoNumber: undefined,
                        }),
                        context.commit('setOpenTableName', null),
                        context.commit('clearSale')
                    ]);
                }).catch(err => errorHandler.tomcatError(err));
        },
        processKazangLayByPayment: async (context, payload) => {
            let saleDetails = context.getters.getSaleDetails;
            saleDetails.type = `Kazang`;
            let saleData = await context.$store.dispatch('parseInvoice', saleDetails);
            saleData.paidAmount = payload.amount;
            return context.dispatch(`processLayByPayment`);
        },
        acceptLayByPayment: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            payload.deviceId = settings.PC;
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(serverApi + `layby/${store.StoreDB}/${payload.id}/payment`, payload)
                .then(() => context.dispatch(`printLayBySale`, payload.id))
                .catch(err => errorHandler.tomcatError(err))
        },

        printLayBySale: async (context, id) => {
            let user = await Storage.getCurrentUser();
            return axios.post(hardwareApi + `printing/printLayBy/${id}?user=${user.Username}`)
                .catch(err => {
                    let response = err.response;
                    if (response) {
                        if (response.status === 404) {
                            return responses.throwErr(`Hardware Service Outdated , Please Contact Lyt-Soft Support to Update your Hardware Service`, `Unable to Print LayBy`)
                        }
                    }
                    return errorHandler.tomcatError(err);
                });
        },
    }
}
