import axios from "axios";
import {Storage} from "@/services/Storage";
import store from "@/store";
import router from "@/router";

let getNow = function () {
    let date = new Date();
    let d = date.toISOString().split('T')[0]
    let t = date.toISOString().split('T')[1].split('.')[0];
    return d + ` ` + t;
}
const isEmpty = function (obj) {
    if (!obj) {
        return true;
    }
    return Object.keys(obj).length === 0;
}
let validateSecurity = function (security) {
    return store.dispatch(`validateSecurityPermission`, security);
}
export default {
    name: 'sales',
    state: {
        currentSale: 0,
        saleDiscount: {},
        currentSaleLines: [],
        receipts: [],
        latestInvoice: {},
        refundInv: undefined,
        discountSpecials: [],
        comboSpecials: [],
        multiSpecials: [],
        tip: 0,
        lpo : undefined,
        saleDetails: {},

    },
    getters: {
        getLatestInvoice: (state) => state.latestInvoice,
        getRefundInvoice: (state) => state.refundInv,
        getCurrentSale: (state) => state.currentSale,
        getDiscountSpecials: (state) => state.discountSpecials,
        getComboSpecials: (state) => state.comboSpecials,
        getMultiSpecials: (state) => state.multiSpecials,
        getSaleLines: (state) => state.currentSaleLines,
        getItemSpecialPrice: (state) => (itemPLU) => {
            let specialPrice = null;

            //Discount Special
            let special = state.discountSpecials.find(sp => sp.Plu === itemPLU)
            if (special) {
                specialPrice = special.SpePrice * 1;
            }

            //Combo Special
            if (!special) {
                special = state.comboSpecials.find(sp => sp.plu === itemPLU);
                if (special) {
                    let specialID = special.special_ID;
                    let groupID = Number(special.group);
                    let otherGroup = groupID === 1 ? 2 : 1;

                    let currentSaleLines = state.currentSaleLines;
                    let otherItems = state.comboSpecials.filter(special => special.special_ID === specialID);
                    otherItems = otherItems.filter(item => Number(item.group) === otherGroup)

                    let commonItems = currentSaleLines.filter(saleLineItem =>
                        otherItems.find(otherItem => otherItem.plu === saleLineItem.Plu)
                    );

                    if (commonItems.length > 0) {
                        state.currentSaleLines = state.currentSaleLines.map(line => {
                            for (let i = 0; i < commonItems.length; i++) {
                                let otherItem = commonItems[i];
                                if (line.index === otherItem.index) {
                                    let otherItemSpecial = otherItems.find(otherItem => otherItem.plu === line.Plu)
                                    if (otherItemSpecial) {
                                        line.Unit = otherItemSpecial.Price;
                                        line.Line = otherItemSpecial.Price * line.Qty - line.Discount;
                                    }
                                }
                            }
                            return line;
                        })
                        specialPrice = special.Price;
                    }
                }
            }

            //Multi-Quantity Special
            if (!special) {
                special = state.multiSpecials.find(sp => sp.Plu === itemPLU);
                if (special) {
                    let quantity = Number(special.HowMany)
                    let price = Number(special.Price)

                    let currentSaleLines = state.currentSaleLines;
                    let saleLines = currentSaleLines.filter(line => line.Plu === itemPLU)
                        .map(saleLine => {
                            let qty = saleLine.Qty;
                            if ((qty + 1) >= quantity) {
                                specialPrice = saleLine.Unit = price;
                                saleLine.Line = price * qty;
                            }
                            return saleLine;
                        });
                    state.currentSaleLines = state.currentSaleLines.map(line => {
                        for (let i = 0; i < saleLines.length; i++) {
                            let saleLine = saleLines[i];
                            if (line.index === saleLine.index) {
                                line.Unit = saleLine.Unit;
                                line.Line = saleLine.Line;
                            }
                        }
                        return line;
                    });
                }
            }
            return specialPrice;
        },
        getTipAmount: (state) => state.tip,
        getLPO: (state) => state.lpo,
        getSaleTotal: (state) => {
            let saleLines = state.currentSaleLines;
            if (!saleLines || saleLines.length === 0) return 0.00;
            return saleLines.map(line => {
                let total = (line.Qty * line.Unit) - line.Discount;
                let instructions = line.instructions;
                if (instructions && instructions.length > 0) {
                    let instructionTotal = instructions.map(i => i.Price).reduce((acc, n) => Number(n) + Number(acc))
                    total += Number(instructionTotal);
                }
                return Number(total);
            }).reduce((sum, line) => Number(sum) + Number(line));
        },
        getSaleDiscount: state => {
            if (!isEmpty(state.saleDiscount)) {
                return state.saleDiscount
            } else return {
                type: 'none', value: 0, total: 0
            }
        },
        getSaleUnits: state => state.currentSaleLines.length,
        getSaleVat: (state, getters) => {
            return getters.getSaleTotal * 0.16;
        },
        getSubTotal: (state, getters) => {
            return getters.getSaleTotal - getters.getSaleVat
        },
        getSaleDetails: (state) => state.saleDetails
    },
    mutations: {
        updateSaleLinePrice: async (state, data) => {
            state.currentSaleLines.map(async (line) => {
                let currentPrice = line.Unit;
                if (line.index === data.index) {
                    let stock1 = await Storage.getAllStock();
                    let item = stock1.find(stock => stock.PLU === data.Plu);
                    if (item) {
                        switch (data.priceType) {
                            case "Normal":
                                currentPrice = item.SellingIncl;
                                break;
                            case "At Cost":
                                currentPrice = item.InclCost;
                                break;
                            case "Price 1":
                                currentPrice = item.price1;
                                break;
                            case "Price 2":
                                currentPrice = item.price2;
                                break;
                            case "Price 3":
                                currentPrice = item.price3;
                                break;
                            case "Price 4":
                                currentPrice = item.price4;
                                break;
                            case "Price 5":
                                currentPrice = item.price5;
                                break;
                            default:
                                currentPrice = line.Unit;
                                break;
                        }

                    }
                }
                line.Unit = currentPrice;
                line.Line = (Number(currentPrice * Number(line.Qty)).toFixed(2) ) - line.Discount;
                return line;
            });
        },
        generateSaleUUID: (state) => state.saleUUID = generateUUID(),
        setDiscountSpecials: async (state, payload) => {
            if (payload) await Storage.setDiscountSpecials(payload)
            else payload = await Storage.getDiscountSpecials()
            state.discountSpecials = payload;
        },
        setSaleCurrency: async (state, payload) => {
            if (payload) state.saleCurrency = payload
            else state.saleCurrency = await Storage.getBaseCurrency();
        },

        setMultiSpecials: async (state, payload) => {
            if (payload) await Storage.setMultiSpecials(payload)
            else payload = await Storage.getMultiSpecials()
            state.multiSpecials = payload;
        },

        setComboSpecials: async (state, payload) => {
            if (payload) await Storage.setComboSpecials(payload)
            else payload = await Storage.getComboSpecials()
            state.comboSpecials = payload;
        },

        setCurrentSale: (state, data) => state.currentSale = data,
        setSaleDetails: (state, data) => state.saleDetails = data,
        setRefundInvoice: (state, data) => {
            state.refundInv = data;
            if (data.customer) {
                state.customer = data.customer;
            }
        },
        reIndexSale: (state) => {
            let lines = state.currentSaleLines;
            for (let i = 0; i < lines.length; i++) {
                lines[i].index = i;
            }
            state.currentSaleLines = lines;
        },
        setCurrentLines: (state, data) => state.currentSaleLines = data,
        addToCurrentSaleLines: async (state, payload) => {
            let data = payload;
            let item = state.currentSaleLines.find(line => {
                return line.Plu === data.Plu &&
                    line.Unit === data.Unit &&
                    line.Description === data.Description;
            });
            let settings = await Storage.getDeviceSettings();
            if (item && !(settings.SeperateLine) && !item.voucher) {
                item.Qty = Number(item.Qty) + Number(payload.Qty);
                item.Line = Number(payload.Unit * item.Qty - item.Discount).toFixed(2);
                const data = [...payload.instructions, ...item.instructions];
                let edited = data.reduce((accumulator, msg) => {
                    if (accumulator.has(msg.CookingNum)) {
                        let m = accumulator.get(msg.CookingNum)
                        m.count += msg.count
                    } else {
                        accumulator.set(msg.CookingNum, msg);
                    }
                    return accumulator;
                }, new Map())
                item.instructions = [...edited.values()]
            } else {
                data.index = state.currentSaleLines.length + 1
                data.savedQty = 0;
                state.currentSaleLines.push(data);
            }
            store.commit(`reIndexSale`)
        },
        voidItem: async (state, payload) => {
            let item = state.currentSaleLines.find(s => s.index === payload.index)
            let validation = await validateSecurity(`Void`);
            if (!validation) return;
            let voidObj = {
                VoidPlu: item.Plu,
                VoidDescritpiom: item.Description,
                VoidQty: 1,
                VoidValue: item.Unit,
                SuperVisor: validation.supervisor,
            }
            store.dispatch(`logItemVoid`, voidObj)
            item.Qty--;
            item.instructions = item.instructions.map((s) => {
                let msg = payload.instructions.find(m => m.CookingNum === s.CookingNum)
                if (msg) {
                    s.count -= msg.count
                }
                return s;
            });
            if (item.Qty <= 0) {
                let arr = state.currentSaleLines
                state.currentSaleLines = $utils.removeIf(arr, s => s.index !== payload.index)
            }
            await store.dispatch(`calculateTipAmount`)
            store.commit(`reIndexSale`)
        },

        voidLine: async (state, data) => {
            let item = state.currentSaleLines.find(s => s.index === data.index)
            let validation = await validateSecurity(`Void`);
            if (!validation) return;
            let voidObj = {
                VoidPlu: item.Plu,
                VoidDescritpiom: item.Description,
                VoidQty: item.Qty,
                VoidValue: item.Line,
                SuperVisor: validation.supervisor,
            }
            store.dispatch(`logItemVoid`, voidObj)
            let arr = state.currentSaleLines
            state.currentSaleLines = $utils.removeIf(arr, s => {
                return s.index !== data.index;
            })
            await store.dispatch(`calculateTipAmount`)
            store.commit(`reIndexSale`)
            console.log(`Voided`)
        },
        addReceipt: (state, payload) => {
            state.latestInvoice = payload
            state.receipts.push(payload)
            state.currentSaleLines = []
            state.saleDiscount = 0.00
        },
        setLPO : (state, payload) => {
            state.lpo = payload.lpoNumber;
            state.customer = payload.debtorID;
        },
        applyDiscount: (state, payload) => state.saleDiscount = payload,
        clearSale: (state) => {
            state.currentSaleLines = [];
            state.currentSale = 0;
            state.saleDiscount = undefined;
            state.tip = 0;
            state.openTableName = null;
        },
        clearDiscount: (state) => state.saleDiscount = undefined,
        setTipAmount: (state, data) => state.tip = data,
    },
    actions: {
        calculateTipAmount: async (context) => {
            let settings = await Storage.getDeviceSettings();
            let saleTotal = context.getters.getSaleTotal - context.getters.getSaleDiscount.total;
            if (settings && settings.AutoTip > 0) {
                let tip = Number(settings.AutoTip);
                tip = (tip / 100) * saleTotal;
                context.commit(`setTipAmount`, tip)
            }
        },
        parseInvoice: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            let settings = await Storage.getDeviceSettings();
            let tipAmount = context.getters.getTipAmount;
            let total = Number(payload.total) - tipAmount;
            let linesDiscount = 0;
            let items = context.state.currentSaleLines;
            let discountTotal = Number(context.getters.getSaleDiscount.total);
            for (let i = 0; i < items.length; i++) {
                let line = items[i];
                linesDiscount += line.Discount;
                items[i].saleLine = i + 1;
                items[i].Line = (Number(line.Qty) * Number(line.Unit)) - Number(line.Discount);
            }
            payload.change = payload.paid - total - tipAmount;
            let invoice = {
                PC: settings.PC,
                saleNumber: context.state.currentSale,
                date: null,
                loyaltyBarcode : context.getters.getLoyaltyDetails.barcode,
                export: payload.type === `Export`,
                tot: context.getters.isTot,
                user: user.Username,
                lpo: context.state.lpo,
                reference: payload.reference ? payload.reference : ``,
                customer: context.getters.getCustomer,
                paymentType: payload.type === `Export` ? `AccountSale` : payload.type,
                items: items,
                refundReason : payload.refundReason?payload.refundReason:``,
                refundReasonCode : payload.refundReasonCode?payload.refundReasonCode:`13`,
                localId: context.state.saleUUID,
                invoice: (!payload.refund) ? 0 : payload.oldInv,
                discount: discountTotal,
                vat: (!payload.refund) ? parseFloat(context.getters.getSaleVat) : 0,
                subTotal: (!payload.refund) ? parseFloat(context.getters.getSubTotal) : parseFloat(total),
                total: parseFloat(total),
                paidAmount: (!payload.refund) ? parseFloat(payload.paid) : parseFloat(total) + tipAmount,
                change: (!payload.refund) ? parseFloat(payload.change) : 0,
                units: (!payload.refund) ? context.getters.getSaleUnits : payload.items.length,
                refund: payload.refund,
                tip: tipAmount,
            };

            if (payload.type === 'Split') {
                invoice.split = [
                    {
                        method: 'cash',
                        amount: payload.splitArray.cash
                    }, {
                        method: 'card',
                        amount: payload.splitArray.card
                    },

                    {
                        method: 'Kazang',
                        amount: payload.splitArray.Kazang
                    },

                    {
                        method: 'Spenn',
                        amount: payload.splitArray.Spenn
                    },
                ];
                if (context.getters.getHasLoyalty){
                    invoice.split.push({
                        method: 'Loyalty Program',
                        amount: payload.splitArray[`Loyalty Program`]
                    })
                }
                for (let i = 0; i < payload.splitArray.other.length; i++) {
                    let method = payload.splitArray.other[i];
                    invoice.split.push({
                        method: method.name,
                        amount: method.amount
                    });
                }
            }

            if ((payload.type === 'Kazang' || (payload.type === 'Split' && payload.splitArray.Kazang > 0)) ||
                (payload.type === 'Spenn' || (payload.type === 'Split' && payload.splitArray.Spenn > 0))) {
                invoice.SecondRefrence = payload.SecondRefrence
            }

            if (payload.type === 'Kazang') {
                invoice.total += invoice.discount;
            }
            return invoice
        },
        processTransaction: async (context, payload) => {
            let isAllowed = await validateSecurity(`convertToInvoice`);
            if (!isAllowed) return false;

            if (payload.type === `AccountSale` || payload.type === `Export`) isAllowed = await validateSecurity(`AccountSale`);
            if (!isAllowed) return false;

            if (payload.type === `Cash`) isAllowed = await validateSecurity(`Cash`);
            if (!isAllowed) return false;

            if (payload.type === `Card`) isAllowed = await validateSecurity(`Card`);
            if (!isAllowed) return false;
            $utils.showLoading();
            let invoice = await context.dispatch('parseInvoice', payload)
            return context.dispatch('processInvoice', invoice)
                .then(res => {
                    if (res) {
                        return router.push({name: 'viewPayment'})
                    }
                }).finally(() => $utils.hideLoading())
        },

        processInvoice: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let currency = await Storage.getBaseCurrency();
            return axios.post(serverApi + `invoice/${store.StoreDB}/mobile?android=true`, payload)
                .then(async ({data}) => {
                    if (typeof data === 'string') {
                        let correctedData = data.replaceAll(/NaN/g, '0');
                        data = JSON.parse(correctedData);
                    }
                    if (data.status === 200) {

                        if (data.response.zraError && isValidString(data.response.zraError)) {
                            return responses.throwErr(data.response.zraError, `Smart Invoice not Processed`)
                                .then(() => {
                                    data.response.paidAmount = 0;
                                    data.response.change = 0;
                                    return Promise.all([
                                        context.commit('setCurrentSale', 0),
                                        context.commit('setTipAmount', 0),
                                        context.commit('addReceipt', data.response),
                                        context.commit('clearDiscount'),
                                        context.commit('setSaleDetails', {}),
                                        context.commit('setCurrentSale', 0),
                                        context.commit('setCustomer', ""),
                                        context.commit('setLoyaltyDetails', {}),
                                        context.commit(`setSaleCurrency`,currency),
                                        context.commit('setLPO', {
                                            debtorID: ``,
                                            lpoNumber: undefined,
                                        }),
                                        context.commit('setOpenTableName', null),
                                    ]).then(() => true);
                                });
                        }
                        try {
                            context.dispatch(`BtPrint`, {invoice: data.response.invoice, reprint: false});
                        } catch (e) {
                        }
                        return Promise.all([
                            context.commit('setCurrentSale', 0),
                            context.commit('setTipAmount', 0),
                            context.commit('addReceipt', data.response),
                            context.commit('clearDiscount'),
                            context.commit('setSaleDetails', {}),
                            context.commit('setLoyaltyDetails', {}),
                            context.commit('setCurrentSale', 0),
                            context.commit('setCustomer', ""),
                            context.commit('setOpenTableName', null),
                            context.commit('setLPO', {
                                debtorID: ``,
                                lpoNumber: undefined,
                            }),
                        ]).then(() => {
                            Swal.close()
                            return true;
                        });
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },

        BtPrint: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            let a4 = settings.A4Inv
            if (a4) {
                let storeDB = store.StoreDB;
                return openUrl(franchiseApi + `PDFReport/getInvNum/${payload.invoice}/${storeDB}?PC=` + settings.PC);
            }
            if (context.getters.getAndroid) {
                return axios.get(franchiseTomcatApi + `invnumandi/${payload.invoice}/db/${store.StoreDB}?invData=true&rawBt=true&reprint=${payload.reprint}&pc=${settings.PC}`)
                    .then(({data}) => window.location.href = `rawbt:base64,${data}`)
                    .catch(err => errorHandler.tomcatError(err))
            }
            return axios.post(hardwareApi + `printing/printReceicept/${store.StoreDB}/${payload.invoice}/${payload.reprint}`, settings)
                .catch((err) => errorHandler.tomcatError(err, false, `Print Error`))
        },

        printProforma: async (context, payload) => {
            let isAllowed = await validateSecurity(`ReprintBill`);
            if (!isAllowed) return;
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            let user = await Storage.getCurrentUser();
            if (settings && settings.A4Inv) return openUrl(franchiseApi + `SaveTable/PrintQuote/${payload}/${store.StoreDB}`);
            return axios.post(hardwareApi + `printing/printProforma/${store.StoreDB}/${payload}/${user.Username}`, settings)
                .catch((err) => errorHandler.tomcatError(err, false, `Cannot Print Proforma Invoice`))

        },
        printBillSlip: async (context, payload) => {
            let isAllowed = await validateSecurity(`ReprintBill`);
            if (!isAllowed) return;

            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            let user = await Storage.getCurrentUser();
            if (settings && settings.A4Inv) return openUrl(franchiseApi + `SaveTable/PrintQuote/${payload}/${store.StoreDB}`);
            return axios.post(hardwareApi + `printing/printBill/${store.StoreDB}/${payload}/${user.Username}`, settings)
                .catch((err) => errorHandler.tomcatError(err, false, `Print Error`))
        },
        async getAllInvoices(context, payload) {
            let store = await Storage.getDefaultStore();
            payload = {
                ...context.getters.getGlobalDateRange,
                ...payload
            }
            return axios.post(backOfficeTomcatApi + `other/${store.StoreDB}/searchSales`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        async getInvoiceLines(context, inv) {
            let store = await Storage.getDefaultStore();
            return axios.get(backOfficeTomcatApi + `other/${store.StoreDB}/getInvNum`, {params: {invoice: inv}})
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        reprintLastInvoice: async (context) => {
            let isAllowed = await validateSecurity(`Reprint`);
            if (!isAllowed) return;
            $utils.showLoading()
            let invoice = context.getters.getLatestInvoice.invoice;
            if (invoice) {
                return context.dispatch('BtPrint', {invoice: invoice, reprint: true})
                    .finally(() => $utils.hideLoading());
            }
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `completedsale/${store.StoreDB}/getLastInvoiceNumber`)
                .then(({data}) => context.dispatch('BtPrint', {invoice: data, reprint: true}))
                .catch((err) => errorHandler.tomcatError(err)).finally(() => $utils.hideLoading());
        },
        openTable: async (context, tab) => {
            let items = tab.items.map((item, index) => {
                item.index = index;
                item.savedQty = item.Qty;
                return item
            })
            await context.commit('setCurrentSale', tab.saleNumber)
            await context.commit('setCurrentLines', items)
            await context.commit('setCustomer', tab.customer)
            await context.commit('setOpenTableName', tab.Name)
            await store.dispatch(`calculateTipAmount`)
            return true;
        },
        searchCashups: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `cashup/${store.StoreDB}/search`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        logItemVoid: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let settings = await Storage.getDeviceSettings();
            let user = await Storage.getCurrentUser();
            payload.User = user.Username;
            payload.PC = settings.PC;
            payload.Reason = `Sale No. ${context.getters.getCurrentSale}`;
            return axios.post(serverApi + `supervisorOverrides/${store.StoreDB}/logItemVoid`, payload)
                .catch(err => errorHandler.tomcatError(err, false, `Could Not Void`));
        },

        openCashDrawer: async (context) => {
            let isAndroid = context.getters.getAndroid;
            if (isAndroid) return;
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            let settings = await Storage.getDeviceSettings();
            return axios.post(hardwareApi + `printing/openCashdrawer/${store.StoreDB}/${user.Username}`, settings)
        },

        getAllActiveSpecials: async (context) => {
            let store = await Storage.getDefaultStore()
            if (!store) return;
            let DB = store.StoreDB;
            return Promise.all([
                axios.get(serverApi + `specials/${DB}/getActiveSpecials?type=discount`)
                    .then(({data}) => context.commit(`setDiscountSpecials`, data)),

                axios.get(serverApi + `specials/${DB}/getActiveSpecials?type=combo`)
                    .then(({data}) => context.commit(`setComboSpecials`, data)),

                axios.get(serverApi + `specials/${DB}/getActiveSpecials?type=multi`)
                    .then(({data}) => context.commit(`setMultiSpecials`, data)),
            ])
        },

        retrySmartInvoice:async (context , invoiceNumber)=>{
            let store = await Storage.getDefaultStore()
            return axios.get(backOfficeTomcatApi+`other/${store.StoreDB}/sendSmartInvoice?invoice=`+invoiceNumber)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },

    }
}
