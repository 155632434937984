import {Storage} from "@/services/Storage"
import axios from "axios"
import {responses} from "@/utils/responses"

export default {
    name: 'user_security',
    state: {
        userSecurity: undefined,
        isUserSecurityOpen: false
    },
    getters: {
        getUserSecurity: (state) => state.userSecurity,
        isUserSecurityOpen: (state) => state.isUserSecurityOpen
    },
    mutations: {
        setUserSecurity: (state, data) => state.userSecurity = data,
        setUserSecurityOpen: (state, data) => state.isUserSecurityOpen = data
    },
    actions: {
        checkUserSecurity: async (context, security) => {
            return new Promise(async (resolve, reject) => {
                let id = generateUUID();
                // let image = await screenShot()
                let user = await Storage.getCurrentUser();
                let payload = {
                    id: id,
                    //   base64image: image,
                    requestedUser: user.Username,
                    Type: security,
                    promise: {
                        resolve: (value)=>resolve(value),
                        reject: (error)=>reject(error)
                    }
                }
                context.commit('setUserSecurity', payload)
                context.commit('setUserSecurityOpen', true)
            })
                .then((result)=> {
                    return {isConfirmed:true,value:result}
                })
        },
        requestSecurityOverride: async (context,payload) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.post(`${backOfficeTomcatApi}supervisor/${store.StoreDB}/createRequest`, payload)
                .then(({data}) => data)
        },
        cancelSecurityOverrideRequest: async (context,id) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.post(`${backOfficeTomcatApi}supervisor/${store.StoreDB}/cancelRequest/${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
                .finally(()=>{
                    context.commit('setUserSecurityOpen', false)
                    context.commit('setUserSecurity', undefined)
                })
        },
        checkSecurityOverrideRequest: async (context,id) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(`${backOfficeTomcatApi}supervisor/${store.StoreDB}/checkStatus/${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
    }
}
