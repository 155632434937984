import axios from "axios";
import {Storage} from "@/services/Storage";

export default {
    name: 'currencies',
    state: {
        baseCurrency: {
            id:1,
            Symbol: "K",
            Rate: 1,
            Base: true,
            Name: "Kwacha"
        },
        storeCurrencies : [],
        saleCurrency : {},
    },
    getters: {
        getBaseCurrency: (state) => state.baseCurrency,
        getStoreCurrencies: (state) => state.storeCurrencies,
        getCurrencyBySymbol: (state) => (symbol) => state.storeCurrencies.find(currency=>currency.Symbol === symbol),
        getSaleCurrency: (state) => state.saleCurrency,
    },
    mutations: {
        setBaseCurrency: async (state, payload) => {
            if (payload) await Storage.setBaseCurrency(payload)
            else payload = await Storage.getBaseCurrency();
            state.baseCurrency = payload ? payload : {
                Symbol: "K",
                Rate: 1,
                Base: true,
                Name: "Kwacha"
            };
            state.saleCurrency =  state.baseCurrency
        },
        setStoreCurrencies: async (state, payload) => {
            if (payload) await Storage.setStoreCurrencies(payload)
            else payload = await Storage.getStoreCurrencies();
            state.storeCurrencies = payload ? payload : [];
        },

    },
    actions: {
        getAllCurrencies: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            return axios.get(backOfficeTomcatApi+`currency/${storeDB}/getAll`)
                .then(({data})=>context.commit(`setStoreCurrencies`, data))
                .catch(err=>errorHandler.tomcatError(err));
        },
        getBaseCurrency: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            return axios.get(franchiseTomcatApi + `currency/${storeDB}/getBaseCurrency`)
                .then(({data}) => context.commit(`setBaseCurrency`, data))
                .catch(err => errorHandler.tomcatError(err))
        },
    }
}
